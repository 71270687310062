<div class="container">
  <div class="flex justify-content-between align-items-center mb-5 row">
    <!-- <mat-card class="ml-5 card-dash-title">
    </mat-card> -->
    <mat-card-title class="ml-5 font-weight-light">Dashboard</mat-card-title>
    <mat-form-field appearance="none" color="primary" class="w-30 ml-2">
      <mat-label>Filtrar por faixa de datas</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          (keyup)="enterSearch($event)"
          [formControl]="keywordFormDateRangeStart"
          matStartDate
          placeholder="De"
        />
        <input
          (keyup)="enterSearch($event)"
          [formControl]="keywordFormDateRangeEnd"
          matEndDate
          placeholder="Até"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <button
        mat-icon-button
        *ngIf="keywordFormDateRangeStart.value && keywordFormDateRangeEnd.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onSearch()"
        id="clear-keyword"
      >
        <mat-icon>search</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="keywordFormDateRangeStart.value && keywordFormDateRangeEnd.value"
        matSuffix
        aria-label="Clear"
        (click)="clearFilter()"
        id="clear-keyword"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <!-- <mat-grid-list cols="{{ (cardLayout | async)?.columns }}" rowHeight="250px">
    <mat-grid-tile
      *ngFor="let mc of miniCardData"
      [colspan]="(cardLayout | async)?.miniCard.cols"
      [rowspan]="(cardLayout | async)?.miniCard.rows"
    >
      <app-mini-card
        [title]="mc.title"
        [icon]="mc.icon"
        [color]="mc.color"
        [value]="mc.stats"
      >
      </app-mini-card>
    </mat-grid-tile>
  </mat-grid-list> -->
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-3" *ngFor="let mc of miniCardData">
      <app-mini-card
        [title]="mc.title"
        [icon]="mc.icon"
        [color]="mc.color"
        [value]="mc.stats"
      >
      </app-mini-card>
    </div>
  </div>
</div>

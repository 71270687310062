import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService, EntityService } from 'viggo-sistemas-core-web';

import { IDomainOrg } from '../model/domain-org.model';

@Injectable()
export class DomainOrgService extends EntityService<IDomainOrg> {
  constructor(
    protected http: HttpClient,
    protected configService: ConfigService
  ) {
    super({
      baseEntityEndPoint: 'domain_org',
      urlBackend: configService.backendURL
    });
  }
}

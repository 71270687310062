import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DateAdapter } from 'angular-calendar';
import { NgxMaskModule } from 'ngx-mask';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { CepService, ViggoCoreComponentsModule } from 'viggo-sistemas-core-web';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { CardPerfilComponent } from './components/card-perfil-empresa/card-perfil.component';
import { CardPefilFormComponent } from './components/card-perfil-form/card-perfil-form.component';
import { ChangeStatusComponent } from './components/change-status/change-status.component';
import { DialogConfirmComponent } from './components/confirm-delete.component';
import { DialogTutorialInfoComponent } from './components/dialog-tutorial-info/dialog-tutorial-info.component';
import { FormPerfilComponent } from './components/form-perfil/form-perfil.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { ControlsActionComponent } from './components/search-header/components/controls-action/controls-action.component';
import { ControlsViewTypeComponent } from './components/search-header/components/controls-view-type/controls-view-type.component';
import { SearchHeaderComponent } from './components/search-header/components/search-header/search-header.component';
import { SliderHorizontalComponent } from './components/slider-horizontal/slider-horizontal.component';
import { FormatDataDirective } from './directives/format-data.directive';
import { CapitalizePipe, CepPipe, CpfCnpjPipe, SafePipe } from './pipes';
import { TypeOfPipe } from './pipes/typeof/typeof.pipe';

@NgModule({
  declarations: [
    DialogTutorialInfoComponent,
    CepPipe,
    SafePipe,
    CapitalizePipe,
    CpfCnpjPipe,
    TypeOfPipe,
    FormatDataDirective,
    DialogConfirmComponent,
    SliderHorizontalComponent,
    CardHeaderComponent,
    DialogConfirmComponent,
    CardPerfilComponent,
    SearchHeaderComponent,
    ControlsViewTypeComponent,
    ControlsActionComponent,
    HeaderMenuComponent,
    FormPerfilComponent,
    ChangeStatusComponent,
    CardPefilFormComponent
  ],
  imports: [
    CommonModule,
    ViggoCoreComponentsModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    RouterModule,
    FormsModule,
    // mat modules
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatProgressBarModule,
    MatMenuModule,
    ButtonModule,
    ImageModule
  ],
  exports: [
    CepPipe,
    SafePipe,
    CapitalizePipe,
    CpfCnpjPipe,
    TypeOfPipe,
    FormatDataDirective,
    DialogConfirmComponent,
    SliderHorizontalComponent,
    CardHeaderComponent,
    CardPerfilComponent,
    SearchHeaderComponent,
    ControlsViewTypeComponent,
    ControlsActionComponent,
    HeaderMenuComponent,
    FormPerfilComponent,
    ChangeStatusComponent,
    CardPefilFormComponent
  ],
  providers: [
    CepService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class SharedModule {}

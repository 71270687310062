import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
/**
 * Modos de visualizações
 */
export type ViggoCoreSearchHeaderViewMode = 'CARD' | 'GRID';

/**
 * @description
 * Localização do preview no locastorage  
 */
export const PREVIEWKEYLOCALSTORAGE = 'previewtype';

@Component({
  selector: 'controls-view-type',
  templateUrl: './controls-view-type.component.html',
  styleUrls: ['./controls-view-type.component.scss']
})
export class ControlsViewTypeComponent implements AfterViewInit, OnInit {
  @Input() mobileOnly = false;
  @Input() desktopOnly = false;
  /**
   * @description
   * Tipo de vizualização selecionada
   */
  public previewType: ViggoCoreSearchHeaderViewMode = 'GRID';

  /**
   * Evento executado quando é alterado o modo de visualização
   */
  @Output() onChangeViewMode: EventEmitter<
    ViggoCoreSearchHeaderViewMode
  > = new EventEmitter<ViggoCoreSearchHeaderViewMode>();

  constructor(private breakpointObserver: BreakpointObserver){}

  ngOnInit(){
    this.breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.Web
    ])
    .subscribe(
      value => {
        var newViewMode =  'GRID' as ViggoCoreSearchHeaderViewMode;
        if (value.matches) {
          if (value.breakpoints['(max-width: 599.98px)'])
            newViewMode =  'CARD';
          else if (
            value.breakpoints['(min-width: 600px) and (max-width: 959.98px)']
          )
            newViewMode =  'CARD';
          else if (
            value.breakpoints['(min-width: 960px) and (max-width: 1279.98px)']
          )
            newViewMode =  'GRID';
          else
            newViewMode =  'GRID';
        }
        this.previewType = newViewMode;
        localStorage.setItem(PREVIEWKEYLOCALSTORAGE, this.previewType);
        this.onChangeViewMode.emit(this.previewType);
      });
  }

  /**
   * Altera o modelo de visualização selecionado
   * @param newViewMode novo modo
   */
  public changePreviewType(newViewMode: ViggoCoreSearchHeaderViewMode) {
    this.previewType = newViewMode;
    localStorage.setItem(PREVIEWKEYLOCALSTORAGE, this.previewType);
    this.onChangeViewMode.emit(this.previewType);
  }
  /**
   * @lifeCycle
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      const previewSaved = localStorage.getItem(
        PREVIEWKEYLOCALSTORAGE
      ) as ViggoCoreSearchHeaderViewMode;

      this.changePreviewType(previewSaved || 'GRID');
    }, 1);
  }
}



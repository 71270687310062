import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

interface ErrorMessage {
  erro: string;
}

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(public router: Router, public matSnackBar: MatSnackBar) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response) => {
        const msg = response.error as string;
        if (msg.search("user_name_domain_id_uk") !== -1)
          this.matSnackBar.open("Nome de usuário já existe!", "X", {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["msg-error"],
          });
        else if (msg.search("parceiro_domain_id_cpf_cnpj_uk") !== -1)
          this.matSnackBar.open("CPF/CNPJ já cadastrado!", "X", {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["msg-error"],
          });
        else if (msg.search("user_email_domain_id_uk") !== -1)
          this.matSnackBar.open("E-mail já cadastrado!", "X", {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["msg-error"],
          });
        return throwError(response);
      })
    );
  }
}

import { ConfigService, EntityService, TokenService } from 'viggo-sistemas-core-web';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFormacoes } from '../model/formacoes.model';
import { IProfissional } from '../../landing-page/model/register.model';

@Injectable()
export class FormacoesService extends EntityService<IFormacoes> {
constructor(
    protected http: HttpClient,
    protected configService: ConfigService,
    protected tokenService : TokenService
) {
    super({
        urlBackend: configService.backendURL,
        baseEntityEndPoint: 'formacao',
        pluralBaseEntityEndPoint: 'formacoes'
    });
    
    }
    private getHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        if (this.tokenService.getToken())
            headers = headers.append('token', this.tokenService.getToken().id);
        return headers;
    }

  public getInclude ()
  {
      return "especialidade,area,tipo_formacao,unidade_ensino"
  }
   /**
   * Suspender profissional
   * @param entityId identificador do profissional
   */
    public favoritarFormacao(
        entityId: string,
    ){
    const url = `${this.baseURL}/${entityId}/favoritar`;

    const headers = this.getHeaders();

    return this.http.put<{ profissional: null }>(url,null,{headers});
    }

    
}
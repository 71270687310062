import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { map } from "rxjs/operators";
import { DasboardService } from "../../services/dasboard.service";

interface DashboardTemplate {
  title: string;
  stats: number;
  icon: string;
  color: string;
}

@Component({
  selector: "info-dashboard",
  templateUrl: "./dashboard-info.component.html",
  styleUrls: ["./dashboard-info.component.scss"],
  providers: [DasboardService],
})
export class DashboardInfoComponent implements OnInit {
  public keywordFormDateRangeStart = new FormControl();
  public keywordFormDateRangeEnd = new FormControl();

  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(
      map(({ matches }) => {
        if (matches) {
          return {
            columns: 1,
            miniCard: { cols: 1, rows: 1 },
            chart: { cols: 1, rows: 2 },
            table: { cols: 1, rows: 4 },
          };
        }

        return {
          columns: 3,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 2, rows: 2 },
          table: { cols: 2, rows: 4 },
        };
      })
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dashboardService: DasboardService
  ) {}

  miniCardData: DashboardTemplate[] = [];

  public onLoad() {
    this.dashboardService.getValuesDashboard().subscribe({
      next: (value) => {
        this.miniCardData.push({
          title: "Contratações",
          icon: "pi pi-briefcase text-yellow-500 text-xl",
          stats: value.dashboard.contratacoes,
          color: "bg-transparent",
          // icon: 'work',
          // color: 'bg-blue-100'
        });
        this.miniCardData.push({
          title: "Empresas",
          icon: "pi pi-building text-purple-500 text-xl",
          stats: value.dashboard.empresas,
          color: "bg-transparent",
          // icon: 'business',
          // color: 'bg-blue-100'
        });
        this.miniCardData.push({
          title: "Processos seletivos",
          icon: "pi pi-file-edit text-blue-500 text-xl",
          stats: value.dashboard.processo_seletivos,
          color: "bg-transparent",
          // icon: 'description',
          // color: 'bg-blue-100'
        });
        this.miniCardData.push({
          title: "Profissionais",
          icon: "pi pi-users text-black-500 text-xl",
          stats: value.dashboard.profissionais,
          color: "bg-transparent",
          // icon: 'group',
          // color: 'bg-blue-100'
        });
        this.miniCardData.push({
          title: "Profissionais aceitos",
          icon: "mdi mdi-account-multiple-check-outline text-blue-500 text-xl",
          stats: value.dashboard.profissional_aceitos,
          color: "bg-transparent",
          // icon: 'how_to_reg',
          // color: 'bg-blue-100'
        });
      },
    });
  }

  ngOnInit() {
    this.onLoad();
  }

  clearFilter() {
    this.keywordFormDateRangeStart.reset();
    this.keywordFormDateRangeEnd.reset();
    this.miniCardData = [];
    this.onLoad();
  }

  enterSearch(event) {
    if (event.key === "Enter") {
      this.onSearch();
    }
  }

  onSearch() {
    var valueDateStart = `${this.keywordFormDateRangeStart.value.getFullYear()}-${this.keywordFormDateRangeStart.value.getUTCMonth() +
      1}-${this.keywordFormDateRangeStart.value.getDate()}`;
    var valueDateEnd = `${this.keywordFormDateRangeEnd.value.getFullYear()}-${this.keywordFormDateRangeEnd.value.getUTCMonth() +
      1}-${this.keywordFormDateRangeEnd.value.getDate()}`;

    this.dashboardService
      .filtrarPorDatas(valueDateStart, valueDateEnd)
      .subscribe({
        next: (value) => {
          this.miniCardData = [];
          this.miniCardData.push({
            title: "Contratações",
            icon: "pi pi-briefcase text-yellow-500 text-xl",
            stats: value.dashboard.contratacoes,
            color: "bg-transparent",
            // color: 'bg-blue-100'
            // icon: 'work',
          });
          this.miniCardData.push({
            title: "Empresas",
            icon: "pi pi-building text-purple-500 text-xl",
            stats: value.dashboard.empresas,
            color: "bg-transparent",
            // icon: 'business',
            // color: 'bg-blue-100'
          });
          this.miniCardData.push({
            title: "Processos seletivos",
            icon: "pi pi-file-edit text-blue-500 text-xl",
            stats: value.dashboard.processo_seletivos,
            color: "bg-transparent",
            // icon: 'description',
            // color: 'bg-blue-100'
          });
          this.miniCardData.push({
            title: "Profissionais",
            icon: "pi pi-users text-black-500 text-xl",
            stats: value.dashboard.profissionais,
            color: "bg-transparent",
            // icon: 'group',
            // color: 'bg-blue-100'
          });
          this.miniCardData.push({
            title: "Profissionais aceitos",
            icon:
              "mdi mdi-account-multiple-check-outline text-blue-500 text-xl",
            stats: value.dashboard.profissional_aceitos,
            color: "bg-transparent",
            // icon: 'how_to_reg',
            // color: 'bg-blue-100'
          });
        },
      });
  }
}

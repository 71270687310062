<mat-card class="content-search-header mat-elevation-z3">
  <div
    class="d-md-flex justify-content-md-between align-items-center content-filters"
  >
    <div
      class="width-100 d-flex justify-content-between justify-content-md-start align-items-center"
    >
      <button
        *ngIf="hiddenFilter && !mobileOnly"
        [matMenuTriggerFor]="menuFilter"
        pButton
        pRipple
        icon="pi pi-fw pi-angle-down"
        class="p-button-rounded p-button-primary p-button-outlined mt-2 mr-3"
      ></button>

      <mat-menu #menuFilter="matMenu" class="text-center menu-content">
        <div class="content-itens-filter">
          <h6 class="my-2">Filtros avançados</h6>
          <mat-accordion (click)="$event.stopPropagation()">
            <mat-expansion-panel>
              <mat-expansion-panel-header>Status</mat-expansion-panel-header>

              <mat-list>
                <mat-selection-list [formControl]="statusForm">
                  <mat-list-option
                    checkboxPosition="before"
                    color="primary"
                    [value]="status.value"
                    *ngFor="let status of entityStatusList"
                  >
                    {{ status.label }}
                  </mat-list-option>
                </mat-selection-list>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-menu>

      <div id="fields">
        <div class="row">
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInput"
            [class]="stylesFieldFormInput1"
          >
            <mat-label>{{ placeHolder1 }}</mat-label>
            <input
              id="field-pesquisa"
              type="search"
              autocomplete="off"
              matInput
              [formControl]="keywordFormInput1"
              (keyup)="enterSearch($event)"
            />
            <!-- <button
              mat-button
              *ngIf="keywordFormInput1.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onSearch()"
              id="clear-keyword"
            >
              <mat-icon>search</mat-icon>
            </button> 
          -->
            <i
              *ngIf="keywordFormInput1.value"
              (click)="clearFilterInput1()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
          </mat-form-field>

          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInput2"
            [class]="stylesFieldFormInput2"
          >
            <mat-label>{{ placeHolder2 }}</mat-label>
            <input
              id="field-pesquisa"
              type="search"
              autocomplete="off"
              matInput
              [formControl]="keywordFormInput2"
              (keyup)="enterSearch($event)"
            />
            <!-- <button
              mat-button
              *ngIf="keywordFormInput2.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onSearch()"
              id="clear-keyword"
            >
              <mat-icon>search</mat-icon>
            </button> -->
            <i
              *ngIf="keywordFormInput2.value"
              (click)="clearFilterInput2()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInput3"
            [class]="stylesFieldFormInput3"
          >
            <mat-label>{{ placeHolder3 }}</mat-label>
            <input
              id="field-pesquisa"
              type="search"
              autocomplete="off"
              matInput
              [formControl]="keywordFormInput3"
              (keyup)="enterSearch($event)"
            />
            <!-- <button
              mat-button
              *ngIf="keywordFormInput3.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onSearch()"
              id="clear-keyword"
            >
              <mat-icon>search</mat-icon>
            </button> -->
            <i
              *ngIf="keywordFormInput3.value"
              (click)="clearFilterInput3()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInputSelect3"
            color="primary"
            [class]="stylesFieldFormSelect3"
          >
            <mat-label>{{ this.placeHolderSelect3 }}</mat-label>
            <mat-select
              [(ngModel)]="selectedValue"
              [formControl]="keywordSelect3"
            >
              <mat-option
                *ngFor="let option of selectOptions3"
                [value]="option.value"
              >
                {{ option.viewValue }}
              </mat-option>
            </mat-select>
            <i
              *ngIf="keywordSelect3.value"
              (click)="clearFilterSelect3()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInputCpfCnpj"
            color="primary"
            [class]="stylesFieldFormInputCpfCnpj"
          >
            <mat-label>CNPJ</mat-label>
            <input
              matInput
              (keyup)="enterSearch($event)"
              type="text"
              maxlength="18"
              [formControl]="keywordCpf_Cnpj"
              [mask]="cpfCnpjMask"
            />
            <!-- <button
              mat-button
              *ngIf="keywordCpf_Cnpj.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onSearch()"
              id="clear-keyword"
            >
              <mat-icon>search</mat-icon>
            </button> -->
            <i
              *ngIf="keywordCpf_Cnpj.value"
              (click)="clearFilterCpfCnpj()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInputDate1"
            color="primary"
            [class]="stylesFieldFormInputDate1"
          >
            <mat-label>{{ this.labelInputDate1 }}</mat-label>
            <input
              id="field-data-nascimento"
              appFormatData
              matInput
              (keyup)="enterSearch($event)"
              [matDatepicker]="dataPicker"
              [max]="maxDate"
              [formControl]="keywordFormDate1"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dataPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #dataPicker color="primary"></mat-datepicker>
            <!-- <button
              mat-button
              *ngIf="keywordFormDate1.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onSearch()"
              id="clear-keyword"
            >
              <mat-icon>search</mat-icon>
            </button> -->
            <i
              *ngIf="keywordFormDate1.value"
              (click)="clearFilterDate1()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInputDate2"
            color="primary"
            [class]="stylesFieldFormInputDate2"
          >
            <mat-label>{{ this.labelInputDate2 }}</mat-label>
            <input
              id="field-data-nascimento"
              appFormatData
              matInput
              (keyup)="enterSearch($event)"
              [matDatepicker]="dataPicker"
              [formControl]="keywordFormDate2"
            />
            <i
              *ngIf="keywordFormDate2.value"
              (click)="clearFilterDate2()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
            <mat-datepicker-toggle
              matSuffix
              [for]="dataPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #dataPicker color="primary"></mat-datepicker>
            <button
              mat-button
              *ngIf="keywordFormDate2.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onSearch()"
              id="clear-keyword"
            >
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInput4"
            [class]="stylesFieldFormInput4"
          >
            <mat-label>{{ placeHolder4 }}</mat-label>
            <input
              id="field-pesquisa"
              type="search"
              min="0"
              oninput="validity.valid||(value='');"
              autocomplete="off"
              matInput
              [type]="typeInput4"
              [formControl]="keywordFormInput4"
              (keyup)="enterSearch($event)"
            />
            <i
              *ngIf="keywordFormInput4.value"
              (click)="clearFilterInput4()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
            <!-- <button
              mat-button
              *ngIf="keywordFormInput4.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onSearch()"
              id="clear-keyword"
            >
              <mat-icon>search</mat-icon>
            </button> -->
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInput5"
            [class]="stylesFieldFormInput5"
          >
            <mat-label>{{ placeHolder5 }}</mat-label>
            <input
              id="field-pesquisa"
              type="search"
              autocomplete="off"
              matInput
              min="0"
              oninput="validity.valid||(value='');"
              [type]="typeInput5"
              [formControl]="keywordFormInput5"
              (keyup)="enterSearch($event)"
            />
            <i
              *ngIf="keywordFormInput5.value"
              (click)="clearFilterInput5()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
            <!-- <button
              mat-button
              *ngIf="keywordFormInput5.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onSearch()"
              id="clear-keyword"
            >
              <mat-icon>search</mat-icon>
            </button> -->
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInput6"
            [class]="stylesFieldFormInput6"
          >
            <mat-label>{{ placeHolder6 }}</mat-label>
            <input
              id="field-pesquisa"
              type="search"
              autocomplete="off"
              matInput
              min="0"
              oninput="validity.valid||(value='');"
              [type]="typeInput6"
              [formControl]="keywordFormInput6"
              (keyup)="enterSearch($event)"
            />
            <i
              *ngIf="keywordFormInput6.value"
              (click)="clearFilterInput6()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
            <!-- <button
              mat-button
              *ngIf="keywordFormInput6.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onSearch()"
              id="clear-keyword"
            >
              <mat-icon>search</mat-icon>
            </button> -->
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInputSelect1"
            color="primary"
            [class]="stylesFieldFormSelect1"
          >
            <mat-label>{{ this.placeHolderSelect1 }}</mat-label>
            <mat-select
              [(ngModel)]="selectedValue"
              [formControl]="keywordSelect1"
            >
              <mat-option
                *ngFor="let option of selectOptions1"
                [value]="option.value"
              >
                {{ option.viewValue }}
              </mat-option>
            </mat-select>
            <i
              *ngIf="keywordSelect1.value"
              (click)="clearFilterSelect1()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
          </mat-form-field>
          <mat-form-field
            appearance="none"
            *ngIf="this.hiddenInputSelect2"
            color="primary"
            [class]="stylesFieldFormSelect2"
          >
            <mat-label>{{ this.placeHolderSelect2 }}</mat-label>
            <mat-select
              [(ngModel)]="selectedValue2"
              [formControl]="keywordSelect2"
            >
              <mat-option
                *ngFor="let option of selectOptions2"
                [value]="option.value"
              >
                {{ option.viewValue }}
              </mat-option>
            </mat-select>
            <i
              *ngIf="keywordSelect2.value"
              (click)="clearFilterSelect2()"
              matSuffix
              id="clear-keyword"
              class="text-danger pi pi-fw pi-times-circle cursor-pointer"
            >
            </i>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-between justify-content-md-start align-items-baseline"
    >
      <div class="viggo-core-view-type">
        <ng-container *ngTemplateOutlet="prefixTemplateViewMode"></ng-container>
        <controls-view-type
          [mobileOnly]="mobileOnly"
          [desktopOnly]="desktopOnly"
          (onChangeViewMode)="changePreviewType($event)"
        ></controls-view-type>
        <ng-container *ngTemplateOutlet="suffixTemplateViewMode"></ng-container>
      </div>

      <controls-action
        [showDownloadButton]="showDownloadButton"
        [showInfoButton]="showInfoButton"
        [showReloadButton]="showReloadButton"
        [showClearButton]="!disableFilter()"
        [initialValueStatus]="initialValueStatus"
        [hiddenFilter]="mobileOnly ? hiddenFilter : false"
        [statusForm]="statusForm"
        [entityStatusList]="entityStatusList"
        (onClearKeyword)="onClearKeyword()"
        (onChangeStatus)="onChangeStatus.emit($event)"
        (onReloadClick)="onReloadClick.emit()"
        (onDownloadDataClik)="onDownloadDataClik.emit()"
        (onChangeInfoClik)="onChangeInfoClik.emit()"
      >
      </controls-action>
    </div>
  </div>

  <mat-progress-bar
    *ngIf="showLoadingBar"
    mode="indeterminate"
    class="progress"
  ></mat-progress-bar>
</mat-card>

<!--
  AQUI SAO OS CHECKBOX


  <section class="checkbox-section">
    <mat-checkbox color="primary" class="checkbox-margin" [(ngModel)]="cod_prod">Cód. produto</mat-checkbox>
    <mat-checkbox color="primary" class="checkbox-margin" [(ngModel)]="desc">Descrição</mat-checkbox>
    <mat-checkbox color="primary" class="checkbox-margin" [(ngModel)]="cod_barras">Cód. barras</mat-checkbox>
  </section>

      <mat-form-field appearance="none">
        <input
          id="field-pesquisa"
          type="search"
          autocomplete="off"
          matInput
          placeholder="Filtre aqui por ..."
          [formControl]="keywordForm"
        />
        <button
          mat-button
          *ngIf="keywordForm.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearKeyword()"
          id="clear-keyword"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      -->

<!--
  AQUI SAO OS RADIOS BUTTONS

  <mat-radio-group style="display: flex; margin-left: 30px; width: 100%;" aria-label="Selecionar uma opção">
    <mat-radio-button color="primary" style="margin: 10px;"
    (change)="changeEventRadio($event)" [value]="1"
    [checked]="this.stateChecked">Código</mat-radio-button>
    <mat-radio-button color="primary" style="margin: 10px;"
    (change)="changeEventRadio($event)" [value]="2"
    [checked]="this.stateChecked2">Descrição</mat-radio-button>
  </mat-radio-group> -->

import { TokenService, UserService } from 'viggo-sistemas-core-web';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  title = 'filterdoctor';
  public constructor(
    protected userService: UserService,
    protected tokenService: TokenService){}

  public ngOnInit(): void{
    this.donwloadUserImage();

    this.tokenService.onLoggedIn$.subscribe(() => {
      this.donwloadUserImage();
    });
  }

  private donwloadUserImage(): void {
    const user = this.tokenService.getUser();
    if (user && user.photo_id)
      this.userService
        .donwloadUserImage(user.photo_id)
        .subscribe(result => this.tokenService.setUserImage(result));
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cep' })
export class CepPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): string {
    if (!value) return '';
    let cep: string = value;
    if (typeof cep !== 'string') cep = String(value);
    const r = new RegExp(/([0-9]{5})([-])?([0-9]{3})$/);
    cep = cep.replace(r, '$1-$3');
    return cep;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cpfCnpj' })
/**
 * Mascara responsável por formatar cpf ou cnpj
 */
export class CpfCnpjPipe implements PipeTransform {
  /**
   * Função chamada pelo angular para resolver o valor do pipe
   * @param value cpf ou cnpj a ser tratado
   */
  public transform(value: string): string {
    if (!value || typeof value !== 'string') return '';
    // remove tudo que não é numero
    value = value.replace(/\D/g, '');
    if (value.length <= 11) return this.cpfMask(value);
    return this.cnpjMask(value);
  }

  /**
   *  Formata uma string a para o formato do CPF
   * @param valor string formatada
   */
  private cpfMask(valor: string): string {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  /**
   *  Formata uma string para o formato do CNPJ
   * @param valor string a ser formatada
   */
  private cnpjMask(valor: string): string {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }
}

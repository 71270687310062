import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): string {
    if (!value) return '';
    const text: string =
      value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    return text;
  }
}

<!-- <viggo-core-side-menu [appName]="appName" [menuItem]="menuItem" #menu>
  <div class="full-container position-absolute">
    <viggo-core-toolbar
      [appName]="appName"
      (toggleSidenav)="menu.toggle()"
    >
  </viggo-core-toolbar>
  </div>
  <div class="mat-toolbar-single-row"></div>

  <viggo-core-header>
    <viggo-core-breadcrumb [useIcons]="true"></viggo-core-breadcrumb>
  </viggo-core-header>

  <router-outlet></router-outlet>
</viggo-core-side-menu> -->
<div
  class="layout-wrapper"
  [ngClass]="{
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': !ripple,
    'layout-menu-static': isStatic(),
    'layout-menu-overlay': isOverlay(),
    'layout-menu-horizontal': isHorizontal(),
    'layout-menu-slim': isSlim(),
    'layout-menu-active': menuActive,
    'layout-menu-mobile-active': mobileMenuActive,
    'layout-topbar-mobile-active': mobileTopbarActive,
    'layout-rightmenu-active': rightMenuActive,
    'layout-rtl': isRTL
  }"
  [class]="'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme"
>
  <viggo-core-toolbar
    (onMenuButtonClick)="onMenuButtonClick($event)"
    (onTopbarMobileButtonClick)="onTopbarMobileButtonClick($event)"
    [appName]="appName"
    [logoToolBar]="logoToolBar"
    [mobileTopbarActive]="mobileTopbarActive"
    [showFastMenu]="false"
    [menuProfile]="menuProfile"
    [menuFast]="menuFast"
  >
  </viggo-core-toolbar>
  <div class="menu-wrapper" (click)="onMenuClick($event)">
    <div class="layout-menu-container">
      <viggo-core-menu
        [menuItem]="menuItem"
        [isOverlay]="isOverlay()"
        [isHorizontal]="isHorizontal()"
        [isSlim]="isSlim()"
        [isMobile]="isMobile()"
        [isDesktop]="isDesktop()"
        [isStatic]="isStatic()">
      </viggo-core-menu>
    </div>
  </div>

  <div class="layout-main" style="margin-left: 0 !important;">
    <viggo-core-header>
      <viggo-core-breadcrumb [useIcons]="true"></viggo-core-breadcrumb>
    </viggo-core-header>
    <div class="layout-content bg-white">
      <router-outlet></router-outlet>
    </div>

    <viggo-core-footer
      [logoApp]="'assets/images/logo-viggo.png'"
    ></viggo-core-footer>
<!--
    [whatsApp]="{show: true, link: 'https://web.whatsapp.com/'}"
    [instagram]="{show: true, link: 'https://www.instagram.com/viggosistemas/'}"
    [facebook]="{show: true, link: 'https://www.facebook.com/search/top?q=viggo%20sistemas'}" -->
  </div>
</div>

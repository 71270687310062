import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, IloginConfig } from "viggo-sistemas-core-web";
import { DashboardComponent } from "./modules/app-components/dashboard/dashboard.component";
import { LoggedComponent } from "./modules/app-components/logged/logged.component";
import { SelectiveStrategyService } from "./modules/global_services/select-strategy-service/select-strategy.service";
import { UnloggedGuard } from "./shared/guards/unloged.guard";

export const routes: Routes = [
  // {
  //     path: 'auth',
  //     loadChildren: () =>
  //         import('./modules/wrapperModules/auth-wrapper.module').then(
  //         a => a.AuthWrappereModule
  //         ),
  //     data: {
  //         loginConfig: {
  //         softwareHouseSlogan: 'Transformando desafios em Soluções',
  //         softwareHouseLogoURL: 'assets/images/logo-viggo.png'
  //         } as IloginConfig
  //     },
  //     canActivate: [RedirectGuard]
  // },
  {
    path: "reset/:token_id/:domain_name",
    redirectTo: "/auth/reset/:token_id/:domain_name",
  },
  {
    path: "activate/:token/:domain_id/:user_id",
    redirectTo: "landing/activate/:token/:domain_id/:user_id",
  },
  // Landing
  {
    path: "login",
    loadChildren: () =>
      import("./modules/landing-page/landing-page.module").then(
        (m) => m.LandingPageModule
      ),
    canActivate: [UnloggedGuard],
    data: {
      loginConfig: {
        softwareHouseSlogan: "Desenvolvida para conectar profissionais",
        softwareHouseLogoURL: "assets/images/logo.png",
      } as IloginConfig,
    },
  },
  // Workspace
  {
    path: "",
    component: LoggedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "workspace",
        component: DashboardComponent,
        data: {
          breadcrumb: localStorage.getItem("profissional_id")
            ? {
                label: "Perfil",
                icon: "pi pi-fw pi-user",
              }
            : {
                label: "Dashboard",
                icon: "pi pi-fw pi-chart-bar",
              },
        },
      },
      {
        path: "user",
        data: {
          preload: false,
          breadcrumb: {
            label: "Usuário",
            icon: "pi pi-fw pi-user",
          },
          backend_path: "/users",
        },
        loadChildren: () =>
          import("./modules/wrapperModules/user-wrapper.module").then(
            (m) => m.UserWrapperModule
          ),
      },
      {
        path: "perfil",
        loadChildren: () =>
          import("./modules/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: "areas",
        data: {
          preload: false,
          breadcrumb: {
            label: "Áreas",
            icon: "mdi mdi-domain",
          },
        },
        loadChildren: () =>
          import("./modules/areas/areas.module").then((m) => m.AreasModule),
      },
      {
        path: "especialidades",
        data: {
          preload: false,
          breadcrumb: {
            label: "Especialidades",
            icon: "mdi mdi-hospital-box-outline",
          },
        },

        loadChildren: () =>
          import("./modules/especialidades/especialidades.module").then(
            (m) => m.EspecialidadesModule
          ),
      },
      {
        path: "mentorias",
        data: {
          preload: false,
          breadcrumb: {
            label: "Mentorias",
            icon: "mdi mdi-folder-star-outline",
          },
        },
        loadChildren: () =>
          import("./modules/mentorias/mentorias.module").then(
            (m) => m.MentoriasModule
          ),
      },
      {
        path: "processo_seletivos",
        data: {
          preload: false,
          breadcrumb: {
            label: "Detalhar processo seletivo",
            icon: "mdi mdi-clipboard-search-outline",
          },
          // breadcrumb: {
          //     label: 'Processos seletivos',
          //     icon: 'edit_note'
          // },
        },
        loadChildren: () =>
          import("./modules/processo_seletivo/processo_seletivo.module").then(
            (m) => m.ProcessoSeletivoModule
          ),
      },
      {
        path: "alunos",
        data: {
          preload: false,
          breadcrumb: {
            label: "Alunos",
            icon: "mdi mdi-account-group-outline",
          },
        },
        loadChildren: () =>
          import("./modules/alunos/alunos.module").then((m) => m.AlunosModule),
      },
      {
        path: "solicitacoes_mentorias",
        data: {
          preload: false,
          breadcrumb: {
            label: "Solicitações de Mentorias",
            icon: "mdi mdi-forum-outline",
          },
        },
        loadChildren: () =>
          import(
            "./modules/solicitacoes_mentorias/solicitacoes_mentorias.module"
          ).then((m) => m.SolicitacoesMentoriasModule),
      },
      {
        path: "profissionais",
        data: {
          preload: false,
          breadcrumb: {
            label: "Profissionais",
            icon: "mdi mdi-account",
          },
        },
        loadChildren: () =>
          import("./modules/profissionais/profissionais.module").then(
            (m) => m.ProfissionaisModule
          ),
      },
      {
        path: "mentores",
        data: {
          preload: false,
          breadcrumb: {
            label: "Mentores",
            icon: "mdi mdi-account-supervisor-circle-outline",
          },
        },
        loadChildren: () =>
          import("./modules/mentor/mentor.module").then((m) => m.MentorModule),
      },
      {
        path: "contratacoes",
        data: {
          preload: false,
          breadcrumb: {
            label: "Contratados",
            icon: "mdi mdi-account-multiple-plus-outline",
          },
        },
        loadChildren: () =>
          import("./modules/contratados/contratados.module").then(
            (m) => m.ContratadosModule
          ),
      },
      {
        path: "empresas",
        data: {
          preload: false,
          breadcrumb: {
            label: "Empresa",
            icon: "mdi mdi-office-building",
          },
        },
        loadChildren: () =>
          import("./modules/empresa/empresa.module").then(
            (m) => m.EmpresarModule
          ),
      },
      {
        path: "servicos",
        data: {
          preload: false,
          breadcrumb: {
            label: "Serviços",
            icon: "mdi mdi-wrench-cog-outline",
          },
        },
        loadChildren: () =>
          import("./modules/servicos/servicos.module").then(
            (m) => m.ServicosModule
          ),
      },
      {
        path: "experiencia_profissionals",
        data: {
          preload: false,
          breadcrumb: {
            label: "Experiências",
            icon: "mdi mdi-briefcase-outline",
          },
        },
        loadChildren: () =>
          import("./modules/experiencias/experiencias.modules").then(
            (m) => m.ExperienciasModule
          ),
      },
      {
        path: "formacoes",
        data: {
          preload: false,
          breadcrumb: {
            label: "Formações",
            icon: "mdi mdi-school-outline",
          },
        },
        loadChildren: () =>
          import("./modules/formacoes/formacoes.module").then(
            (m) => m.FormacoesModule
          ),
      },
      {
        path: "unidade_ensinos",
        data: {
          preload: false,
          breadcrumb: {
            label: "Unidades de ensino",
            icon: "mdi mdi-domain",
          },
        },
        loadChildren: () =>
          import("./modules/unidades-ensino/unidades-ensino.module").then(
            (m) => m.UnidadeEnsinoModule
          ),
      },
      {
        path: "tipo_formacoes",
        data: {
          preload: false,
          breadcrumb: {
            label: "Tpo de Formações",
            icon: "mdi mdi-school-outline",
          },
        },
        loadChildren: () =>
          import("./modules/tipo-formacao/tipo-formacao.module").then(
            (m) => m.TipoFormacaoModule
          ),
      },
      {
        path: "questao_avaliacoes",
        data: {
          preload: false,
          breadcrumb: {
            label: "Questões de avaliações",
            icon: "mdi mdi-text-box-edit-outline",
          },
        },
        loadChildren: () =>
          import(
            "./modules/questoes-avaliacoes/questao_avaliacoes.module"
          ).then((m) => m.AvaliacoesModule),
      },
      {
        path: "avaliacoes",
        data: {
          preload: false,
          breadcrumb: {
            label: "Avaliações",
            icon: "mdi mdi-text-box-edit-outline",
          },
        },
        loadChildren: () =>
          import("./modules/avaliacao/avaliacao.modules").then(
            (m) => m.AvaliacaoModule
          ),
      },
      {
        path: "termo_aceites",
        data: {
          preload: false,
          breadcrumb: {
            label: "Termos",
            icon: "mdi mdi-file-document-multiple-outline",
          },
        },
        loadChildren: () =>
          import("./modules/termos/termos.module").then((m) => m.TermosModule),
      },
      {
        path: "tipo_convites",
        data: {
          preload: false,
          breadcrumb: {
            label: "Tipos de Convites",
            icon: "mdi mdi-email-check-outline",
          },
        },
        loadChildren: () =>
          import("./modules/convite/convite.module").then(
            (m) => m.ConviteModule
          ),
      },
      {
        path: "convites_selecao",
        data: {
          preload: false,
          breadcrumb: {
            label: "Convites",
            icon: "mdi mdi-email-check-outline",
          },
        },
        loadChildren: () =>
          import("./modules/convites_selecao/convites_selecao.module").then(
            (m) => m.ConvitesSelecaoModule
          ),
      },
      {
        path: "application",
        data: {
          preload: false,
          breadcrumb: {
            label: "Aplicação",
            icon: "pi pi-fw pi-building",
          },
          backend_path: "/applications",
        },
        loadChildren: () =>
          import("./modules/wrapperModules/application-wrapper.module").then(
            (m) => m.ApplicationWrappereModule
          ),
      },
      {
        path: "route",
        data: {
          preload: false,
          breadcrumb: { label: "Rotas", icon: "pi pi-fw pi-directions" },
          backend_path: "/routes",
        },
        loadChildren: () =>
          import("./modules/wrapperModules/rota-wrapper.module").then(
            (m) => m.RouterWrapperModule
          ),
      },
      {
        path: "role",
        data: {
          preload: false,
          breadcrumb: { label: "Role", icon: "pi pi-fw pi-users" },
          backend_path: "/roles",
        },
        loadChildren: () =>
          import("./modules/wrapperModules/role-wrapper.module").then(
            (m) => m.RoleWrapperModule
          ),
      },
      {
        path: "domain",
        data: {
          preload: false,
          breadcrumb: { label: "Domain", icon: "pi pi-fw pi-briefcase" },
          backend_path: "/domains",
        },
        loadChildren: () =>
          import("./modules/wrapperModules/domain-wrapper.module").then(
            (m) => m.DomainWrapperModule
          ),
      },
      { path: "", redirectTo: "/workspace", pathMatch: "full" },
    ],
  },
  {
    path: "**",
    redirectTo: "/login/profile",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectiveStrategyService,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
  providers: [SelectiveStrategyService],
})
export class AppRoutingModule {}

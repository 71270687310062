import { TokenService } from 'viggo-sistemas-core-web';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable()
export class UnloggedGuard implements CanActivate {
  public constructor(
    protected tokenService: TokenService,
    protected router: Router
  ) {}

  canActivate(): boolean {
    if (this.tokenService.getUser()) {
      this.router.navigate(['workspace']);
      return false;
    }

    return true;
  }
}

import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit
} from '@angular/core';

import { ScreenService } from '@app/shared/services';

@Component({
  selector: 'slider-horizontal',
  templateUrl: './slider-horizontal.component.html',
  styleUrls: ['./slider-horizontal.component.scss'],
  animations: [
    trigger('showHide', [
      transition(':enter', [
        style({
          transform: 'translateY(-50%) scale(0) rotate(45deg) '
        }),
        animate(
          '.2s',
          style({
            transform: 'translateY(-50%) scale(1) rotate(0deg) '
          })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateY(-50%)' }),
        animate(
          '.2s',
          style({
            transform: 'translateY(-50%) rotate(45deg) scale(0)'
          })
        )
      ])
    ])
  ],
  providers: [ScreenService]
})
export class SliderHorizontalComponent implements AfterViewInit {
  /**
   * Referencia ao container com os elementos
   */
  @ViewChild('content', { read: ElementRef })
  private contentElementRef: ElementRef<HTMLElement>;
  /**
   * Lugar onde o slider está
   */
  @ViewChild('wrapper', { read: ElementRef })
  private wrapperElementRef: ElementRef<HTMLElement>;

  /**
   * Atual valor do transform
   */
  public currentScrollOffset = 0;

  /**
   * Controla se o botão de avançar será exibido
   */
  public showNextButton = false;
  /**
   * Controla se o botão de voltar será exibido
   */
  public showPreviousButton = false;
  /**
   * Flag se a tela é mobile
   */
  protected telaMobile = false;

  /**
   * Escuta os eventos de mudanças de redimensionamento
   * setando as novas configurações
   */
  @HostListener('window:resize')
  onResize = () => {
    this.verificarSeEMobile();
    this.currentScrollOffset = 0;
    this.setTransformStyle(0);
    this.calcularMostrarBotaoNext();
  };

  /**
   * Faz a checagem se a tela do usuário é mobile
   */
  private verificarSeEMobile(): boolean {
    const eMobile = this.screenService.eMobile();

    if (eMobile) {
      this.showNextButton = false;
      this.showPreviousButton = false;

      this.contentElementRef.nativeElement.classList.add('content-scroll-auto');
      this.wrapperElementRef.nativeElement.classList.add('content-scroll-auto');
    } else this.showPreviousButton = true;

    this.telaMobile = eMobile;

    return eMobile;
  }

  public constructor(
    protected render: Renderer2,
    protected screenService: ScreenService
  ) {}

  /**
   * Calcula o novo offset da pagina
   */
  calcNewOffset(
    direction: 'prev' | 'next',
    widths: {
      content: number;
      wrapper: number;
    },
    currentScrollOffset: number
  ) {
    let sliceOffset = widths.wrapper;
    if (direction === 'prev') sliceOffset *= -1;

    const targetOffset = currentScrollOffset + sliceOffset;

    const calcOffset = Math.min(targetOffset, widths.content - widths.wrapper);

    return Math.max(calcOffset, 0);
  }

  /**
   * Aplica o transform a div do scroll
   * @param transformValue
   */
  private setTransformStyle(transformValue: number): void {
    const element = this.contentElementRef.nativeElement;
    const styleValue = `translateX(${transformValue}px)`;

    const keyStyle = 'transform';

    this.render.setStyle(element, keyStyle, styleValue);
  }

  /**
   * @lifeCycle
   */
  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.calcularMostrarBotaoNext();
      this.verificarSeEMobile();
    }, 2);
  }

  /**
   * @view
   * Navega até uma página a mais
   */
  public nextPage(): void {
    const widths = {
      wrapper: this.wrapperElementRef.nativeElement.clientWidth,
      content: this.contentElementRef.nativeElement.clientWidth
    };
    const newCurrentOffset = this.calcNewOffset(
      'next',
      widths,
      this.currentScrollOffset
    );

    this.currentScrollOffset = newCurrentOffset;

    this.calcularMostrarBotaoNext();

    this.setTransformStyle(this.currentScrollOffset * -1);
  }

  /**
   * @view
   * Retorna uma página do scroll
   */
  public previousPage(): void {
    this.currentScrollOffset = this.calcNewOffset(
      'prev',
      {
        wrapper: this.wrapperElementRef.nativeElement.clientWidth,
        content: this.contentElementRef.nativeElement.scrollWidth
      },
      this.currentScrollOffset
    );

    this.setTransformStyle(this.currentScrollOffset * -1);
  }

  /**
   * Calcula se o botao de next ficara visivel
   */
  protected calcularMostrarBotaoNext() {
    if (this.telaMobile) this.showNextButton = false;
    else
      this.showNextButton =
        this.currentScrollOffset +
          this.wrapperElementRef.nativeElement.clientWidth !==
        this.contentElementRef.nativeElement.clientWidth;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'typeof' })
export class TypeOfPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): string {
    enum TYPES {
      object = "Objeto",
      string = "String",
      number = "Numero",
      array = "Lista",
      boolean = "Boolean"
    }
  
    let rootVal = value;
    if (
      typeof value === "object" &&
      Object.prototype.toString.call(value) === "[object Array]"
    )
      return TYPES["array"];
    return TYPES[typeof rootVal];
  }
}

import { Injectable } from "@angular/core";
import { IRoute, MenuService, RouterItemPg } from "viggo-sistemas-core-web";

/**
 * Ordena 2 itens de menu em ordem alfabética
 */
const ordemAlfabetica = (a: RouterItemPg, b: RouterItemPg) =>
  a.label > b.label ? 1 : -1;

@Injectable()
export class CustomMenuService extends MenuService {
  // defina suas rotas aqui;
  protected createDynamicsMenuPg(routes: IRoute[]): RouterItemPg[] {
    /**
     * é requirido que você chame a classe super para definir as rotas padrões
     */
    let defaultMenu = super.createDynamicsMenuPg(routes);

    const informacoesMenu = this.createInformaçõesMenu(routes);
    if (informacoesMenu) defaultMenu = defaultMenu.concat(informacoesMenu);

    const ensinoMenu = this.createEnsinoMenu(routes);
    if (ensinoMenu) defaultMenu = defaultMenu.concat(ensinoMenu);

    const mentorMenu = this.createMentorMenu(routes);
    if (mentorMenu) defaultMenu = defaultMenu.concat(mentorMenu);

    const profissionalsMenu = this.createProfissionalMenu(routes);
    if (profissionalsMenu) defaultMenu = defaultMenu.concat(profissionalsMenu);

    const servicosMenu = this.createServicosMenu(routes);
    if (servicosMenu) defaultMenu = defaultMenu.concat(servicosMenu);

    const contratadosMenu = this.createContratadosMenu(routes);
    if (contratadosMenu) defaultMenu = defaultMenu.concat(contratadosMenu);

    const contratacaoMenu = this.createProcessoSeletivoMenu(routes);
    if (contratacaoMenu) defaultMenu = defaultMenu.concat(contratacaoMenu);

    return defaultMenu;
  }

  createMentorMenu(routes: IRoute[]) {
    let menu: RouterItemPg;

    let submenu = [] as RouterItemPg[];

    submenu = this.filterGrantMenuItens(routes, [
      {
        menuItem: {
          label: "Solicitações Mentorias",
          isSubMenu: false,
          icon: "mdi mdi-forum-outline",
          routerLink: ['/solicitacoes_mentorias'],
          idElement: 'menu-solicitacoes',
          precedence: 1
        },
        matchBackendRoutes: [
          {
            method: "PUT",
            url: "/mentorias/<id>",
          },
        ],
      },
      {
        menuItem: {
          label: "Alunos",
          isSubMenu: false,
          icon: "mdi mdi-account-group-outline",
          routerLink: ['/alunos'],
          idElement: 'menu-alunos',
          precedence: 2
        },
        matchBackendRoutes: [
          {
            method: "PUT",
            url: "/mentorias/<id>",
          },
        ],
      },
    ]);
    submenu.sort(ordemAlfabetica);

    if (submenu.length > 0)
      menu = {
        label: "Mentor",
        icon: "mdi mdi-account-supervisor-circle-outline",
        routerLink: null,
        idElement: 'menu-mentor',
        items: submenu
      };

    return menu;
  }

  createProfissionalMenu(routes: IRoute[]) {
    let menu: RouterItemPg;

    let submenu = [] as RouterItemPg[];

    submenu = this.filterGrantMenuItens(routes, [
      {
        menuItem: {
          label: "Formações",
          isSubMenu: false,
          icon: "mdi mdi-school-outline",
          routerLink: ['/formacoes'],
          idElement: 'menu-formacoes',
          precedence: 1
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/formacoes",
          },
        ],
      },
      {
        menuItem: {
          label: "Experiências",
          isSubMenu: false,
          icon: "mdi mdi-briefcase-outline",
          routerLink: ['/experiencia_profissionals'],
          idElement: 'menu-experiencias',
          precedence: 2
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/experiencia_profissionals",
          },
        ],
      },
      {
        menuItem: {
          label: "Mentorias",
          isSubMenu: false,
          icon: "mdi mdi-folder-star-outline",
          routerLink: ['/mentorias'],
          idElement: 'menu-mentorias',
          precedence: 3
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/mentorias",
          },
        ],
      },
      {
        menuItem: {
          label: "Convites",
          isSubMenu: false,
          icon: "mdi mdi-email-check-outline",
          routerLink: ['/convites_selecao'],
          idElement: 'menu-convites',
          precedence: 4
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/mentorias",
          },
        ],
      },
    ]);
    submenu.sort(ordemAlfabetica);

    if (submenu.length > 0)
      menu = {
        label: "Profissional",
        icon: "mdi mdi-account-check-outline",
        routerLink: null,
        idElement: 'menu-profissional',
        items: submenu
      };

    return menu;
  }

  createInformaçõesMenu(routes: IRoute[]) {
    let menu: RouterItemPg;

    let submenu = [] as RouterItemPg[];

    submenu = this.filterGrantMenuItens(routes, [
      {
        menuItem: {
          label: "Profissionais",
          isSubMenu: false,
          icon: "mdi mdi-account",
          routerLink: ['/profissionais'],
          idElement: 'menu-profissionais',
          precedence: 1
        },
        matchBackendRoutes: [
          {
            method: "PUT",
            url: "/profissionais/<id>/ativar",
          },
        ],
      },
      {
        menuItem: {
          label: "Mentores",
          isSubMenu: false,
          icon: "mdi mdi-account-supervisor-circle-outline",
          routerLink: ['/mentores'],
          idElement: 'menu-mentores',
          precedence: 2
        },
        matchBackendRoutes: [
          {
            method: "DELETE",
            url: "/profissionais/<id>",
          },
        ],
      },
      {
        menuItem: {
          label: "Empresas",
          isSubMenu: false,
          icon: "mdi mdi-office-building",
          routerLink: ['/empresas'],
          idElement: 'menu-empresas',
          precedence: 3
        },
        matchBackendRoutes: [
          {
            method: "PUT",
            url: "/empresas/<id>/ativar",
          },
        ],
      },
    ]);

    submenu.sort(ordemAlfabetica);

    if (submenu.length > 0)
      menu = {
        label: "Informações do usuário",
        icon: "mdi mdi-account-group-outline",
        routerLink: null,
        idElement: 'menu-info-user',
        items: submenu
      };

    return menu;
  }

  createProcessoSeletivoMenu(routes: IRoute[]) {
    let menu: RouterItemPg;

    let submenu = [] as RouterItemPg[];

    submenu = this.filterGrantMenuItens(routes, [
      // {
      //   menuItem: {
      //     label: 'Contratações',
      //     icon: 'work',
      //     routerLink: ['/processo_seletivos'],
      //   },
      //   matchBackendRoutes: [
      //     {
      //       method: 'POST',
      //       url: '/processo_seletivos'
      //     }
      //   ]
      // },
      {
        menuItem: {
          label: "Iniciar processos",
          isSubMenu: false,
          icon: "mdi mdi-note-check-outline",
          routerLink: ['/processo_seletivos/iniciar'],
          idElement: 'menu-iniciar-processos',
          precedence: 1
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/processo_seletivos",
          },
        ],
      },
      {
        menuItem: {
          label: "Detalhar processos",
          isSubMenu: false,
          icon: "mdi mdi-clipboard-search-outline",
          routerLink: ['/processo_seletivos'],
          idElement: 'menu-detalhar-processos',
          precedence: 2
        },
        matchBackendRoutes: [
          {
            method: "PUT",
            url: "/processo_seletivos/<id>",
          },
        ],
      },
    ]);
    submenu.sort(ordemAlfabetica);

    if (submenu.length > 0)
      menu = {
        label: "Processos Seletivos",
        icon: "mdi mdi-briefcase-outline",
        routerLink: null,
        idElement: 'menu-processos',
        items: submenu
      };

    return menu;
  }

  /**
   * Exibe somente para admin
   * @param routes
   * @returns
   */
  createEnsinoMenu(routes: IRoute[]) {
    let menu: RouterItemPg;

    let submenu = [] as RouterItemPg[];

    submenu = this.filterGrantMenuItens(routes, [
      {
        menuItem: {
          label: "Áreas",
          isSubMenu: false,
          icon: "mdi mdi-domain",
          routerLink: ['/areas'],
          idElement: 'menu-areas',
          precedence: 1
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/areas",
          },
        ],
      },
      {
        menuItem: {
          label: "Especialidades",
          isSubMenu: false,
          icon: "mdi mdi-hospital-box-outline",
          routerLink: ['/especialidades'],
          idElement: 'menu-especialidades',
          precedence: 2
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/especialidades",
          },
        ],
      },
      {
        menuItem: {
          label: "Questões de avaliações",
          isSubMenu: false,
          icon: "mdi mdi-text-box-edit-outline",
          routerLink: ['/questao_avaliacoes'],
          idElement: 'menu-questoes',
          precedence: 3
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/questao_avaliacoes",
          },
        ],
      },

      {
        menuItem: {
          label: "Termos",
          isSubMenu: false,
          icon: "mdi mdi-file-document-multiple-outline",
          routerLink: ['/termo_aceites'],
          idElement: 'menu-termos',
          precedence: 4
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/termo_aceites",
          },
        ],
      },
      {
        menuItem: {
          label: "Tipos de convites",
          isSubMenu: false,
          icon: "mdi mdi-email-check-outline",
          routerLink: ['/tipo_convites'],
          idElement: 'menu-convites',
          precedence: 5
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/tipo_convites",
          },
        ],
      },
      {
        menuItem: {
          label: "Tipos de formações",
          isSubMenu: false,
          icon: "mdi mdi-school-outline",
          routerLink: ['/tipo_formacoes'],
          idElement: 'menu-formacoes',
          precedence: 6
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/tipo_formacoes",
          },
        ],
      },
      {
        menuItem: {
          label: "Unidades de ensino",
          isSubMenu: false,
          icon: "mdi mdi-domain",
          routerLink: ['/unidade_ensinos'],
          idElement: 'menu-unidade-ensino',
          precedence: 7
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/unidade_ensinos",
          },
        ],
      },
    ]);
    // submenu.sort(ordemAlfabetica);

    if (submenu.length > 0)
      menu = {
        label: "Parametrização",
        icon: "mdi mdi-plus-box-multiple-outline",
        routerLink: null,
        idElement: 'menu-parametrizacao',
        items: submenu
      };

    return menu;
  }

  createContratadosMenu(routes: IRoute[]) {
    //let menu : RouterItemPg | RouterItemPg[];

    let menu: RouterItemPg[] = this.filterGrantMenuItens(routes, [
      {
        menuItem: {
          label: "Contratados",
          isSubMenu: false,
          icon: "mdi mdi-account-multiple-plus-outline",
          routerLink: ['/contratacoes'],
          idElement: 'menu-contratados'
        },
        matchBackendRoutes: [
          {
            method: "POST",
            url: "/contratacoes",
          },
        ],
      },
    ]);
    return menu;
  }

  /**
   * Exibe somente para empresa
   * @param routes
   * @returns
   */
  createServicosMenu(routes: IRoute[]) {
    //let menu : RouterItemPg | RouterItemPg[];

    let menu: RouterItemPg[] = this.filterGrantMenuItens(routes, [
      {
        menuItem: {
          label: "Serviços",
          isSubMenu: false,
          icon: "mdi mdi-wrench-cog-outline",
          routerLink: ['/servicos'],
          idElement: 'menu-servicos'
        },
        matchBackendRoutes: [
          {
            method: "PUT",
            url: "/empresas/<id>/adicionar_servico",
          },
        ],
      },
    ]);
    return menu;
  }
}

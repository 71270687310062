<div class="container">
  <div class="app-loading" *ngIf="dados === undefined">
    <h4 class="text-splash">
      Preparando perfil, por-favor aguarde!
    </h4>
      <div class="content-spinner">
        <svg class="spinner" viewBox="25 25 50 50">
          <circle
            class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke-width="2"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
  </div>
  <div *ngIf="dados !== undefined">   
    <card-perfil *ngIf="dados.name !== 'admin'" [dados]="dados"></card-perfil>
  </div>
</div>
<div *ngIf="dados !== undefined">
  <info-dashboard *ngIf="dados.name === 'admin'"></info-dashboard>    
</div>
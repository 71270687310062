import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ConfigService,
  EntityService,
  TokenService,
} from "viggo-sistemas-core-web";
import { ITermo } from "../model/termo.model";

@Injectable()
export class TermoService extends EntityService<ITermo> {
  constructor(
    protected http: HttpClient,
    protected configService: ConfigService,
    protected tokenService: TokenService
  ) {
    super({
      urlBackend: configService.backendURL,
      baseEntityEndPoint: "termo_aceite",
    });
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.tokenService.getToken())
      headers = headers.append("token", this.tokenService.getToken().id);
    return headers;
  }

  public downloadFile(id: string): void {
    const url = `${this.baseURL}/${id}`;

    this.http
      .get(url, {
        headers: this.getHeader(),
        responseType: "blob",
      })
      .subscribe((response: any) => {
        const blob = new Blob([response], {
          type: "application/pdf",
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  public uploadFile(file: File, entity: ITermo) {
    const url = `${this.baseURL}`;

    const headers = this.getHeaders();

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("entity", JSON.stringify(entity));

    return this.http.post(url, formData, { headers });
  }

  public updateTermo(file: File, entity: ITermo) {
    const url = `${this.baseURL}/${entity.id}`;

    const headers = this.getHeaders();

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("entity", JSON.stringify(entity));

    return this.http.put(url, formData, { headers });
  }
}

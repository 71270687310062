import { ConfigService, EntityService, TokenService } from 'viggo-sistemas-core-web';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProfissional } from '../../../modules/landing-page/model/register.model';

@Injectable()
export class ProfissionalService extends EntityService<IProfissional> {
    constructor(
        protected http: HttpClient,
        protected configService: ConfigService,
        protected tokenService : TokenService
    ) {
    super({
            urlBackend: configService.backendURL,
            pluralBaseEntityEndPoint: 'profissionais',
            baseEntityEndPoint: 'profissional'
        });
    }

    private getHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        if (this.tokenService.getToken())
            headers = headers.append('token', this.tokenService.getToken().id);
        return headers;
    }

   
    /**
   * Ativar profissional
   * @param entityId identificador do profissional
   */
    public ativarProfissional(
        entityId: string,
    ){
        const url = `${this.baseURL}/${entityId}/ativar`;

        const headers = this.getHeaders();

        return this.http.put<{ profissional: IProfissional }>(url,null, {headers});
    }

    /**
   * Inativar profissional
   * @param entityId identificador do profissional
   */
    public inativarProfissional(
        entityId: string,
    ){
        const url = `${this.baseURL}/${entityId}/inativar`;

        const headers = this.getHeaders();

        return this.http.put<{ profissional: IProfissional }>(url,null, {headers});
    }

    /**
   * Suspender profissional
   * @param entityId identificador do profissional
   */
    public suspenderProfissional(
        entityId: string,
    ){
        const url = `${this.baseURL}/${entityId}/suspender`;

        const headers = this.getHeaders();

        return this.http.put<{ profissional: IProfissional }>(url,null,{headers});
    }
}
<div class="control-view-type">
  <button
    class="button-view-mode"
    *ngIf="false"
    [ngClass]="{ active: previewType === 'CARD' }"
    (click)="changePreviewType('CARD')"
    mat-icon-button
    id="btn-view-mode-card"
  >
    <mat-icon>view_module</mat-icon>
  </button>
  <button
    id="btn-view-mode-grid"
    class="button-view-mode"
    *ngIf="false"
    [ngClass]="{ active: previewType === 'GRID' }"
    mat-icon-button
    (click)="changePreviewType('GRID')"
  >
    <mat-icon>view_list</mat-icon>
  </button>
</div>

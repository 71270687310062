<div class="content-component">
  <button
    @showHide
    *ngIf="currentScrollOffset !== 0 && showPreviousButton"
    (click)="previousPage()"
    class="indicator left"
    mat-mini-fab
  >
    <mat-icon svgIcon="arrow_to_left"></mat-icon>
  </button>

  <div class="wrapper" #wrapper>
    <div class="content" #content>
      <!-- lugar onde os elementos são aplicados -->
      <ng-content></ng-content>
    </div>
  </div>

  <button
    *ngIf="showNextButton"
    @showHide
    (click)="nextPage()"
    class="indicator right"
    mat-mini-fab
  >
    <mat-icon svgIcon="arrow_to_right"></mat-icon>
  </button>
</div>

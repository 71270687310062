import { ConfigService, TokenService } from 'viggo-sistemas-core-web';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDashboard } from '../model/dashboard.model';

@Injectable()
export class DasboardService{
    constructor(
        protected http: HttpClient,
        protected configService: ConfigService,
        protected tokenService : TokenService
    ) {}

    private getHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        if (this.tokenService.getToken())
            headers = headers.append('token', this.tokenService.getToken().id);
        return headers;
    }

    public getValuesDashboard() : Observable<IDashboard>{
        let url = `${this.configService.backendURL}dashboards`
        const headers = this.getHeaders()
        return this.http.get<IDashboard>(url, {headers});
    }

    public filtrarPorDatas(de: string, ate: string){
        let params = new HttpParams();
        const headers = this.getHeaders()

        let url = `${this.configService.backendURL}dashboards`

        params = params.set('de', de);
        params = params.set('ate', ate);

        return this.http.get<IDashboard>(url, {headers, params})
    }
}
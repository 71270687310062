import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import {
  FormServiceValidationMessages,
  TokenService,
  UserService,
  ViggoCoreCustomInputComponent,
} from "viggo-sistemas-core-web";
import { FormacoesService } from "../../../modules/formacoes/services/formacoes.service";
import { IUser } from "../../../modules/landing-page/model/user.model";
import { IParceiro } from "../../../modules/parceiro/model/parceiro.model";
import { ParceiroService } from "../../../modules/parceiro/services/parceiro.service";
import { EmpresaService } from "../../../modules/profile/services/empresa.service";
import { ProfissionalService } from "../../../modules/profile/services/profissional.service";

@Component({
  selector: "card-perfil[dados]",
  templateUrl: "./card-perfil.component.html",
  styleUrls: ["./card-perfil.component.scss"],
  providers: [
    ParceiroService,
    ProfissionalService,
    EmpresaService,
    FormacoesService,
  ],
})
export class CardPerfilComponent implements OnInit, AfterViewInit {
  public readState = true;
  formacao = "";
  noFormations = false;
  imagemDefault = "../../../../assets/images/avatar.png";

  @ViewChildren(ViggoCoreCustomInputComponent)
  elementsInputs: QueryList<ViggoCoreCustomInputComponent>;
  @ViewChild("inputImage", { read: ElementRef })
  inputImage: ElementRef<HTMLInputElement>;
  controlFormImageFile = new FormControl(null, []);

  /**
   * Informações a serem mostradas
   */
  @Input("dados") dados!: IUser;

  @Input("photoProfile") foto!: Blob;

  typeProfile: string;
  media_mentores = 0;
  media_clinicas = 0;
  messageService: FormServiceValidationMessages;
  form: FormGroup;
  parceiros: IParceiro[];
  displayMessage = {};
  loadingRequest = false;

  @Input() entity: IParceiro;
  @Output() onCancelClick = new EventEmitter();

  @Output() onLoadParceiro = new EventEmitter<IParceiro>();

  public constructor(
    public router: Router,
    protected formBuilder: FormBuilder,
    protected matSnackBar: MatSnackBar,
    protected profissionalService: ProfissionalService,
    protected empresaService: EmpresaService,
    protected parceiroService: ParceiroService,
    protected userService: UserService,
    protected tokenService: TokenService,
    protected formacoesService: FormacoesService
  ) {}

  /**
   * Responsável por verificar a existência de uma instância na tabela empresa através do id do usuário
   * @returns o objeto do tipo empresa
   */
  verificarDados() {
    const obs = this.empresaService.getEntitiesByParams(
      `user_id=${this.dados.id}&include=parceiro`
    );
    obs.subscribe((value) => {
      if (value.length === 0) return this.verificarDadosProfissional();
      else {
        this.entity = value[0].parceiro;
        localStorage.setItem("empresa_id", this.entity.id);
        this.typeProfile = "empresa";
      }
    });
    // return this.verificarDadosParceiro(obs)
  }

  /**
   * Responsável por verificar a existência de uma instância na tabela profissional através do id do usuário
   * @returns o objeto do tipo profissional
   */
  verificarDadosProfissional() {
    const obs = this.profissionalService.getEntitiesByParams(
      `user_id=${this.dados.id}&include=parceiro,formacoes.tipo_formacao`
    );
    obs.subscribe((value) => {
      if (value.length === 0)
        this.matSnackBar.open("Perfil não encontrado!", "X", {
          duration: 2000,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ["msg-error"],
        });
      else {
        this.entity = value[0].parceiro;
        value[0].media_mentores
          ? (this.media_mentores = value[0].media_mentores)
          : null;
        value[0].media_clinicas
          ? (this.media_clinicas = value[0].media_clinicas)
          : null;
        value[0].formacoes.length > 0
          ? (this.formacao = value[0].formacoes.find(
              (formacao) => formacao.id === value[0].formacao_favorita_id
            ).tipo_formacao.nome)
          : null;
        this.typeProfile = "profissional";
      }
    });
    return this.verificarDadosParceiro(obs);
  }

  /**
   * Resgata as informações da entidade na tabela de parceiros
   * @param obs da entidade seja ela profissional ou empresa
   * @returns info de parceiro
   */
  verificarDadosParceiro(obs: Observable<any>) {
    var subject = new Subject<IParceiro>();
    obs.subscribe((value) => {
      this.parceiroService.getEntity(value[0].id).subscribe((value) => {
        this.entity = value;
        localStorage.setItem("profissional_id", value.id);
        subject.next(value);
      });
    });
    return subject.asObservable();
  }

  ngOnInit(): void {
    this.verificarDados();
  }

  navigateToProfissionalProfile() {
    this.router.navigateByUrl(`perfil/profissional/${this.entity.id}`);
  }

  navigateToEmpresaProfile() {
    this.router.navigateByUrl(`perfil/empresa/${this.entity.id}`);
  }

  /**
   * Monitora o valor armazenado pelo input do tipo file
   */
  public ngAfterViewInit(): void {
    this.controlFormImageFile.valueChanges.subscribe(() => {
      const fileList = this.inputImage.nativeElement.files;
      if (!(fileList instanceof FileList)) return;
      if (fileList.length === 0) return;
      const imageFile = fileList.item(0);

      this.uploadPhoto(imageFile);
    });
  }

  /**
   * Responsável por resgatar o arquivo selecionado e verificar a extensão, caso seja
   * aceito ele atualiza o banco de imagens e a referencia do photo_id
   * @param imageFile arquivo de imagem
   * @returns atualização do perfil
   */
  protected uploadPhoto(imageFile: File): void {
    const regexAcceptFormat = /^.*\.(jpeg|jpg|png|gif)$/;

    if (imageFile.name && !imageFile.name.match(regexAcceptFormat)) {
      this.matSnackBar.open(
        "Formato não aceito, são aceitas fotos com a extensão jpeg, jpg, png, gif.",
        "X",
        {
          duration: 2000,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ["msg-error"],
        }
      );
      return;
    }
    this.loadingRequest = true;
    this.userService
      .uploadPhoto(this.dados.id, imageFile)
      .pipe(
        finalize(() => {
          this.loadingRequest = false;
        })
      )
      .subscribe(
        ({ image }) => {
          this.matSnackBar.open("Imagem alterada com sucesso.", "X", {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["msg-success"],
          });
          this.tokenService.saveUser({
            ...this.tokenService.getUser(),
            photo_id: image.id,
          });
          this.tokenService.setUserImage(imageFile);
          this.reloadPhotoProfile();
        },
        () =>
          this.matSnackBar.open(
            "Estamos com problemas para salvar a imagem.",
            "X",
            {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-error"],
            }
          )
      );
    this.inputImage.nativeElement.value = null;
  }

  reloadPhotoProfile() {
    this.dados = this.tokenService.getUser();
  }
}

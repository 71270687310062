import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

interface menu_perfil {
  title: string;
  icon: string;
}

@Component({
  selector: "header-menu",
  templateUrl: "./header-menu.component.html",
  styleUrls: ["./header-menu.component.scss"],
})
export class HeaderMenuComponent {
  @Input() entityId: string;
  @Input() menu: menu_perfil[];
  @Input() route_path: string;

  constructor(protected actRouter: ActivatedRoute, protected router: Router) {}

  navigate(value) {
    if (value === "Dados")
      this.router.navigate([`${this.route_path}/${this.entityId}`]);
    else if (value === "Formações")
      this.router.navigate([
        `${this.route_path}/list-formacoes/${this.entityId}`,
      ]);
    else if (value === "Experiências")
      this.router.navigate([
        `${this.route_path}/list-experiencias/${this.entityId}`,
      ]);
    else if (value === "Mentorias")
      this.router.navigate([
        `${this.route_path}/list-mentores/${this.entityId}`,
      ]);
    else if (value === "Alunos")
      this.router.navigate([`${this.route_path}/list-alunos/${this.entityId}`]);
    else if (value === "Convites")
      this.router.navigate([
        `${this.route_path}/list-convites/${this.entityId}`,
      ]);
    else if (value === "Processos seletivos")
      this.router.navigate([
        `${this.route_path}/list-processos/${this.entityId}`,
      ]);
    else if (value === "Contratados")
      this.router.navigate([
        `${this.route_path}/list-contratados/${this.entityId}`,
      ]);
  }
}

import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectionList } from "@angular/material/list";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { IGrant } from "../../../modules/landing-page/model/grant.model";
import { GrantService } from "../../../modules/landing-page/services/grant.service";
import { EmpresaService } from "../../../modules/profile/services/empresa.service";
import { ProfissionalService } from "../../../modules/profile/services/profissional.service";
import { RoleOverrideService } from "../../services/role_override.service";

export interface ValueItem {
  icon: string;
  label: string;
  value: string;
}

@Component({
  selector: "app-change-status",
  templateUrl: "./change-status.component.html",
  providers: [
    ProfissionalService,
    GrantService,
    EmpresaService,
    RoleOverrideService,
  ],
})
export class ChangeStatusComponent implements OnInit {
  @Input() entityProfileList: Array<ValueItem> = [];

  @Input() entityStatusList: Array<ValueItem> = [];

  @Input() userId: string;
  @Input() entityId: string;
  @Input() tipo_entity = "";
  grants: IGrant[];
  mentor_role_id = "";

  @ViewChild(MatSelectionList) protected matSelectionList: MatSelectionList;

  public statusForm: FormControl;

  constructor(
    protected grantService: GrantService,
    protected profissionalService: ProfissionalService,
    protected empresaService: EmpresaService,
    protected roleService: RoleOverrideService,
    protected router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  public ngOnInit() {
    this.statusForm = new FormControl();
    this.roleService
      .getEntitiesByParams(`name=Mentor`)
      .subscribe((value) => (this.mentor_role_id = value[0].id));
    this.grantService
      .getByUser(this.userId)
      .subscribe((value) => (this.grants = value));
  }

  changeStatus(value: string) {
    this.tipo_entity === "empresa"
      ? this.changeStatusEmpresa(value)
      : this.changeStatusProfissional(value);
  }

  changeStatusEmpresa(value: string) {
    if (value === "ativar") this.ativarEntidade("empresa");
    else if (value === "inativar") this.inativarEntidade("empresa");
    else this.suspenderEntidade("empresa");
  }

  changeStatusProfissional(value: string) {
    if (value === "ativar") this.ativarEntidade("profissional");
    else if (value === "inativar") this.inativarEntidade("profissional");
    else this.suspenderEntidade("profissional");
  }

  ativarEntidade(tipo: string) {
    tipo === "empresa"
      ? this.empresaService.ativarEmpresa(this.entityId).subscribe(
          () => {
            this.matSnackBar.open("Sucesso ao ativar a empresa!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-success"],
            });
            this.router.navigate(["empresas"]);
          },
          (error) => {
            this.matSnackBar.open("Erro ao ativar a empresa!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-error"],
            });
          }
        )
      : this.profissionalService.ativarProfissional(this.entityId).subscribe(
          () => {
            this.matSnackBar.open("Sucesso ao ativar o profissional!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-success"],
            });
            this.router.url.startsWith("/mentores")
              ? this.router.navigate(["mentores"])
              : this.router.navigate(["profissionais"]);
          },
          (error) => {
            this.matSnackBar.open("Erro ao ativar o profissional!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-error"],
            });
          }
        );
  }

  inativarEntidade(tipo: string) {
    tipo === "empresa"
      ? this.empresaService.inativarEmpresa(this.entityId).subscribe(
          () => {
            this.matSnackBar.open("Sucesso ao inativar a empresa!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-success"],
            });
            this.router.navigate(["empresas"]);
          },
          (error) => {
            this.matSnackBar.open("Erro ao inativar a empresa!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-error"],
            });
          }
        )
      : this.profissionalService.inativarProfissional(this.entityId).subscribe(
          () => {
            this.matSnackBar.open("Sucesso ao inativar o profissional!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-success"],
            });
            this.router.url.startsWith("/mentores")
              ? this.router.navigate(["mentores"])
              : this.router.navigate(["profissionais"]);
          },
          (error) => {
            this.matSnackBar.open("Erro ao inativar o profissional!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-error"],
            });
          }
        );
  }

  suspenderEntidade(tipo: string) {
    tipo === "empresa"
      ? this.empresaService.suspenderEmpresa(this.entityId).subscribe(
          () => {
            this.matSnackBar.open("Sucesso ao suspender a empresa!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-success"],
            });
            this.router.navigate(["empresas"]);
          },
          (error) => {
            this.matSnackBar.open("Erro ao suspender a empresa!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-error"],
            });
          }
        )
      : this.profissionalService.suspenderProfissional(this.entityId).subscribe(
          () => {
            this.matSnackBar.open("Sucesso ao suspender o profissional!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-success"],
            });
            this.router.url.startsWith("/mentores")
              ? this.router.navigate(["mentores"])
              : this.router.navigate(["profissionais"]);
          },
          (error) => {
            this.matSnackBar.open("Erro ao suspender o profissional!", "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-error"],
            });
          }
        );
  }

  initializeGrantObject(user_id: string, role_id: string) {
    return {
      user_id: user_id,
      role_id: role_id,
    } as IGrant;
  }

  changeProfile(status: string) {
    if (status === "mentor") {
      this.grantService
        .insert(this.initializeGrantObject(this.userId, this.mentor_role_id))
        .subscribe(
          (value) => {
            this.grants.push(value);
            this.matSnackBar.open(`Tornou um mentor!`, "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-success"],
            });
            this.router.url.startsWith("/mentores")
              ? this.router.navigate(["profissionais"])
              : this.router.navigate(["mentores"]);
          },
          (error) => {
            this.matSnackBar.open(`Erro ao adicionar como mentor!`, "X", {
              duration: 2000,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["msg-error"],
            });
          }
        );
    } else {
      if (this.grants.length <= 2) {
        this.matSnackBar.open("Esse profissional não é mentor!", "X", {
          duration: 2000,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ["msg-error"],
        });
      }
      this.grants.map((grant) => {
        if (grant.role_id === this.mentor_role_id) {
          this.grantService.delete(grant).subscribe(
            () => {
              this.grants.pop();
              this.matSnackBar.open(`Tornou um profissional!`, "X", {
                duration: 2000,
                horizontalPosition: "right",
                verticalPosition: "top",
                panelClass: ["msg-success"],
              });
              this.router.url.startsWith("/mentores")
                ? this.router.navigate(["profissionais"])
                : this.router.navigate(["mentores"]);
            },
            (error) => {
              this.matSnackBar.open("Esse profissional não é mentor!", "X", {
                duration: 2000,
                horizontalPosition: "right",
                verticalPosition: "top",
                panelClass: ["msg-error"],
              });
            }
          );
        }
      });
    }
  }
}

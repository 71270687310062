<div
  class="d-flex align-items-center w-100 justify-content-between"
  style="margin-bottom: 12px;"
>
  <div id="left" class="d-flex align-items-center">
    <ng-template #defaultBack>
      <button mat-icon-button (click)="back.emit()" >
        <i class="mdi mdi-arrow-left gray"></i>
      </button>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="backAction? backAction: defaultBack"
    >
    </ng-container>
    <h4 class="title-header ml-1">
      <ng-content></ng-content>
    </h4>
  </div>
  <div id="right" class="d-flex align-items-center ">
    <ng-template #defaultButtons>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!actionIsValid"
        (click)="submit.emit()"
      >
        Salvar
      </button>
    </ng-template>
    <ng-container *ngTemplateOutlet="prefixButtons"></ng-container>
    <ng-container
      *ngTemplateOutlet="actionsButtons ? actionsButtons : defaultButtons"
    >
    </ng-container>
  </div>
</div>

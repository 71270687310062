import { ConfigService } from 'viggo-sistemas-core-web';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent {
  @Input() isSubmit = true;

  @Input() actionIsValid = false;

  @Output() submit: EventEmitter<any> = new EventEmitter();

  @Output() back: EventEmitter<any> = new EventEmitter();

  @Input() title = '';

  @Input() navigate = 'workspace';

  @Input('actionsButtons')
  actionsButtons: TemplateRef<HTMLElement>;

  @Input('prefixButtons')
  prefixButtons: TemplateRef<HTMLElement>;

  @Input('backAction')
  backAction: TemplateRef<HTMLElement>;

  constructor(protected route: Router, protected config: ConfigService) {}

  click(): void {
    this.submit.emit();
  }

  goBack(): void {
    this.route.navigateByUrl(this.navigate);
  }
}

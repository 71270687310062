<mat-card class="card-no-icon bg-white mat-elevation-z5 mx-auto">
  <mat-card-content>
    <div class="row width-100 m-0" autocomplete="off">
      <mat-form-field
        appearance="none"
        [class]="
          entity.cros && entity.cros.length !== 0 ? 'col-md-7' : 'col-md-9'
        "
      >
        <mat-label>Nome</mat-label>
        <input
          matInput
          [disabled]="true"
          type="text"
          [value]="entity.parceiro.nome_razao_social"
        />
      </mat-form-field>
      <mat-form-field
        appearance="none"
        class="col-md-2"
        *ngIf="entity.cros && entity.cros.length !== 0"
      >
        <mat-label>Registro CRO</mat-label>
        <input
          matInput
          [disabled]="true"
          type="text"
          [value]="entity.cros[0].cro"
        />
      </mat-form-field>
      <mat-form-field appearance="none" class="col-md-3">
        <mat-label>CPF/CNPJ</mat-label>
        <input
          matInput
          [disabled]="true"
          type="text"
          [value]="cpfCnpjPipe.transform(entity.parceiro.cpf_cnpj)"
        />
      </mat-form-field>
      <mat-form-field
        appearance="none"
        [class]="!empresa ? 'col-md-3' : 'col-md-4'"
        [ngClass]="{ form_empresa: empresa }"
      >
        <mat-label>E-mail</mat-label>
        <input
          matInput
          [disabled]="true"
          type="text"
          [value]="entity.user.email"
        />
      </mat-form-field>
      <mat-form-field
        appearance="none"
        [class]="!empresa ? 'col-md-2' : 'col-md-3'"
        [ngClass]="{ form_empresa: empresa }"
      >
        <mat-label>Celular</mat-label>
        <input matInput [disabled]="true" type="text" [value]="contato_tel" />
      </mat-form-field>
  
      <mat-form-field appearance="none" class="col-md-2" *ngIf="!empresa">
        <mat-label>Graduado</mat-label>
        <input matInput [disabled]="true" type="text" [value]="entity.graduado" />
      </mat-form-field>
  
      <mat-form-field appearance="none" class="col-md-2">
        <mat-label>CEP</mat-label>
        <input matInput [disabled]="true" type="text" [value]="cep" />
      </mat-form-field>
  
      <mat-form-field appearance="none" class="col-md-3">
        <mat-label>Municipio/UF</mat-label>
        <input matInput [disabled]="true" type="text" [value]="cidade" />
      </mat-form-field>
  
      <mat-form-field appearance="none" class="col-md-12">
        <mat-label>Logradouro</mat-label>
        <input matInput [disabled]="true" type="text" [value]="logradouro" />
      </mat-form-field>
      <mat-form-field appearance="none" class="col-md-12">
        <mat-label>Descrição do perfil</mat-label>
        <textarea
          style="width: 100%; height: 500px; resize: none;"
          [value]="entity.parceiro.observacao"
          matInput
          [disabled]="true"
        ></textarea>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DownloadFileService {
  /**
   * Baixa um arquivo para a máquina do usuário
   * @param file arquivo a ser baixado
   * @param nomeDownload nome do arquivo no download
   */
  public downloadFile(file: Blob, nomeDownload = 'arquivo'): void {
    const htmlAElement = document.createElement('a');
    htmlAElement.href = URL.createObjectURL(file);
    htmlAElement.download = nomeDownload;
    htmlAElement.click();
  }
}

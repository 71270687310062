import { Component, Inject, AfterViewInit, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface IDataDialogTutorialInfoComponent {
  /**
   * Texto de ajuda
   */
  text: string;
  /**
   * Titulo da entidade
   */
  titleEntity: string;
  /**
   * Endereco remoto do video
   */
  srcVideo: string;
}

@Component({
  templateUrl: './dialog-tutorial-info.component.html',
  styleUrls: ['./dialog-tutorial-info.component.scss']
})
export class DialogTutorialInfoComponent implements AfterViewInit, OnInit {
  /**
   * Controla a animação do expansio panel
   */
  public disableAnimation = true;
  /**
   * Recurso de video transfromado
   */
  urlVideo: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDataDialogTutorialInfoComponent,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (!this.data.titleEntity) throw new Error('O titulo é obrigatório.');
    if (!this.data.text) throw new Error('O texto de ajuda é obrigatório.');
    if (!this.data.srcVideo)
      throw new Error('O endereo do video é obrigatório.');

    this.urlVideo = this.getURLVideo();
  }

  ngAfterViewInit() {
    // ativa a nimacao para nao animar ao abrir o dialog
    setTimeout(() => {
      this.disableAnimation = false;
    }, 1);
  }

  /**
   * Transforma a string do src em um recurso safe, para o angular
   * não informar um erro
   */
  private getURLVideo(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.srcVideo);
  }
}

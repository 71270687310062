import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { NgxMaskModule } from 'ngx-mask';
import {
  ViggoCoreBreadCrumbModule,
  ViggoCoreFooterModule,
  ViggoCoreHeaderModule,
  ViggoCoreModule,
  ViggoCorePageNotFoundModule,
  ViggoCoreSideMenuModule,
  ViggoCoreToolBarModule
} from 'viggo-sistemas-core-web';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DashboardComponent } from './modules/app-components/dashboard/dashboard.component';
import { LoggedComponent } from './modules/app-components/logged/logged.component';
import { DashboardInfoComponent } from './modules/dashboard-info/views/info/dashboard-info.component';
import { MiniCardComponent } from './modules/dashboard-info/views/mini-card/mini-card.component';
import { CustomMenuService } from './modules/global_services/menu-service/menu.service';
import { AppRoutingModule } from './routes';
import { RedirectGuard } from './shared/guards/redirect.guard';
import { UnloggedGuard } from './shared/guards/unloged.guard';
import { GlobalHttpInterceptorService } from './shared/services/global-interceptor.service';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localePt);
@NgModule({
  declarations: [
    AppComponent,
    LoggedComponent,
    DashboardComponent,
    DashboardInfoComponent,
    MiniCardComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: () => adapterFactory(moment)
    }),
    NgxMaskModule.forRoot({ validation: false }),
    ViggoCoreModule.forRoot({
      interceptors: {
        includeAuthInterceptor: {
          enable: true
        }
      }
    }),
    MatSnackBarModule,
    //MODULOS DO ViggoCore
    ViggoCoreBreadCrumbModule,
    ViggoCorePageNotFoundModule,
    ViggoCoreFooterModule,
    ViggoCoreSideMenuModule,
    ViggoCoreToolBarModule,
    ViggoCoreHeaderModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([]),
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    MatInputModule,
    MatDatepickerModule
  ],
  providers: [
    CustomMenuService,    
    UnloggedGuard,
    RedirectGuard,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 4000 }
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        animationDuration: '300ms'
      }
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: GlobalHttpInterceptorService,
        multi: true
    },
  ],
    
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    // fazendo registro dos paths de icones
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons.svg')
    );
  }
}

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { EmpresaService } from "../../modules/profile/services/empresa.service";
import { TermoService } from "../../modules/termos/services/termo.service";

@Component({
  template: `
    <mat-dialog-content>
      <h5>Excluir {{ data.nomeEntidade }}</h5>
      <p>
        Deseja excluir a(o) {{ data.nomeEntidade }}
        {{ data.descricaoEntidade }} ?
      </p>
    </mat-dialog-content>

    <div matDialogActions class="w-100 d-flex justify-content-between">
      <button
        [matDialogClose]="false"
        id="denny-btn"
        mat-stroked-button
        color="primary"
        type="button"
      >
        Cancelar
      </button>
      <button
        [matDialogClose]="true"
        id="confirm-btn"
        mat-flat-button
        color="primary"
        type="submit"
        (click)="action()"
      >
        Confirmar
      </button>
    </div>
  `,
  providers: [EmpresaService, TermoService],
})
export class DialogConfirmComponent {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public router: Router,
    public snackBar: MatSnackBar,
    protected empresaService: EmpresaService,
    protected termoService: TermoService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      nomeEntidade: string;
      descricaoEntidade: string;
      entity?: any;
      entity_id?: string;
      sub_entity_id?: string;
    }
  ) {}

  action() {
    if (this.data.nomeEntidade === "Serviço") {
      var id = this.data.entity_id;
      var servico_id = this.data.sub_entity_id;
      this.empresaService.removerServicos(servico_id, id).subscribe(
        () => {
          this.snackBar.open(`Serviço removido com sucesso`, "X", {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["msg-success"],
          });
        },
        (error) => {
          this.snackBar.open(`Erro ao remover! ${error}`, "X", {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["msg-error"],
          });
        }
      );
    } else if (this.data.nomeEntidade === "Termo") {
      this.termoService.delete(this.data.entity).subscribe(
        () => {
          this.snackBar.open("Termo de deletado com sucesso!", "X", {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["msg-success"],
          });
        },
        (error) => {
          this.snackBar.open(`Erro ao deletar o termo! ${error}`, "X", {
            duration: 2000,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ["msg-error"],
          });
        }
      );
    }
  }
}

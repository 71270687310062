<div class="max-card-dialog-info">
  <div class="p-3">
    <div class="text-primary d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <mat-icon class="mr-3">store</mat-icon>
        <span class="font-weight-bold">
          {{ data.titleEntity }}
        </span>
      </div>
      <button
        mat-dialog-close
        class="font-weight-bold"
        type="button"
        mat-button
        color="primary"
      >
        Fechar
      </button>
    </div>
    <p class="mt-3">
      {{ data.text }}
    </p>
  </div>

  <mat-expansion-panel
    [@.disabled]="disableAnimation"
    style="box-shadow: none;background-color: white;"
  >
    <mat-expansion-panel-header>
      <span class="text-primary d-flex align-items-center">
        <mat-icon class="mr-3">play_circle_filled</mat-icon>
        <span class="font-weight-bold ">
          Demonstrações
        </span>
      </span>
    </mat-expansion-panel-header>

    <div style="height: 360px;">
      <iframe class="w-100 h-100" [src]="urlVideo"> </iframe>
    </div>
  </mat-expansion-panel>
</div>

<div
  class="row"
  [ngClass]="{ 'menu-profissional': route_path !== 'empresas' }"
>
  <div *ngFor="let menu_item of menu" class="m-2">
    <button
      class="actions-menu"
      pButton
      pRipple
      class="p-button p-button-primary p-button-outlined mr-2 p-button-rounded"
      (click)="navigate(menu_item.title)"
    >
      <mat-icon style="margin-right: 5px;">{{ menu_item.icon }}</mat-icon>
      <span>{{ menu_item.title }}</span>
    </button>
  </div>
</div>

<mat-card class="content">
  <!-- <img
    style="width: 100%; height: 100%; object-fit: contain;"
    class="img-empresa rounded-circle ml-2"
    draggable="false"
    src="{{ entity.user.photo }}"
    alt="Logo empresa"
  /> -->
  <img
    class="img-empresa"
    draggable="false"
    [src]="entity.user.photo || imagemDefault"
    alt="Foto perfil"
  />
  <div class="informacoes-empresa">
    <div
      style="display: flex; align-items: center; justify-content: space-between;"
    >
      <h3 class="display-name" color="primary">{{ entity.user.name }}</h3>
      <app-change-status
        [entityProfileList]="grant_profile"
        [entityId]="entity.id"
        [entityStatusList]="menu_expansion"
        [tipo_entity]="tipo_entity"
        [userId]="entity.user_id"
      >
      </app-change-status>
    </div>
    <div
      class="descricao-empresa"
      *ngIf="!noFormations && tipo_entity !== 'empresa'"
    >
      <!-- <pre style="margin-left: 15px">Formado em:</pre> -->
      <div style="margin-left: 10px;">
        <mat-card *ngIf="formacao" class="card-formation">
          {{ formacao }}
        </mat-card>
        <mat-card *ngIf="formacao === ''" class="card-formation">
          Sem formação
        </mat-card>
      </div>
    </div>
    <span class="descricao-empresa" *ngIf="noFormations"
      >Nenhuma formação cadastrada ainda!</span
    >
    <div style="display: flex;" *ngIf="tipo_entity !== 'empresa'">
      <mat-card
        *ngIf="media_mentores !== 0"
        style="border-radius: 30px; margin-right: 10px;"
      >
        <h3 class="nome-user">
          Média Mentores: {{ media_mentores.toFixed(2) }}
        </h3>
      </mat-card>
      <mat-card
        *ngIf="media_clinicas !== 0"
        style="border-radius: 30px; margin-right: 10px;"
      >
        <h3 class="nome-user">
          Média Clínicas: {{ media_clinicas.toFixed(2) }}
        </h3>
      </mat-card>
    </div>
  </div>
</mat-card>

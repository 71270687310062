import { ConfigService, EntityService } from 'viggo-sistemas-core-web';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IGrant } from '../model/grant.model';



@Injectable()
export class GrantService extends EntityService<IGrant> {
  constructor(
    protected http: HttpClient,
    protected configService: ConfigService
  ) {
    super({
      urlBackend: configService.backendURL,
      baseEntityEndPoint: 'grant'
    });
  }

  public getByUser(userId: string): Observable<IGrant[]> {
    return this.http
      .get<IGrant[]>(`${this.baseURL}?user_id=${userId}&include=role`, {
        headers: this.getHeader()
      })
      .pipe(
        map(data => data[this.getEntitiesName()]),
        catchError(this.handleError)
      );
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[appFormatData]'
})
export class FormatDataDirective {
  public abstractControl: AbstractControl;

  constructor(private control: NgControl, private element: ElementRef) {
    element.nativeElement.maxLength = 10;
  }

  ngOnInit(): void {
    this.abstractControl = this.control.control;
  }

  @HostListener('keyup', ['$event.target.value'])
  inputChanged(value: string): void {
    const regex = /(\d{2})[-|/](\d{2})[-|/](\d{4})/g;
    if (regex.test(value) && value.length === 10) {
      const [year, month, day] = value.replace(regex, '$3-$2-$1').split('-');
      this.abstractControl.setValue(new Date(+year, +month - 1, +day));
    } else if (/\d{8}/g.test(value) && value.length === 8) {
      const [year, month, day] = value
        .replace(/(\d{2})(\d{2})(\d{4})/, '$3-$2-$1')
        .split('-');
      this.abstractControl.setValue(new Date(+year, +month - 1, +day));
    }
  }

  @HostListener('blur', ['$event.target.value'])
  inputBlur(value: string): void {
    const regex = /(\d{2})[-|/](\d{2})[-|/](\d{2})/g;
    if (regex.test(value) && value.length === 8) {
      const [year, month, day] = value.replace(regex, '$3-$2-$1').split('-');
      this.abstractControl.setValue(new Date(+year, +month - 1, +day));
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import {
  ImagemService,
  TokenService,
  UserService,
} from "viggo-sistemas-core-web";
import { IUser } from "../../landing-page/model/user.model";
import { GrantService } from "../../landing-page/services/grant.service";
import { EmpresaService } from "../../profile/services/empresa.service";
import { ProfissionalService } from "../../profile/services/profissional.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [
    GrantService,
    ImagemService,
    UserService,
    ProfissionalService,
    EmpresaService,
  ],
})
export class DashboardComponent implements OnInit {
  public dados!: IUser;
  public typeEntity: string;

  constructor(
    protected userService: UserService,
    protected imagemService: ImagemService,
    protected tokenService: TokenService,
    protected profissionalService: ProfissionalService,
    protected empresaService: EmpresaService,
    public matSnackBar: MatSnackBar
  ) {}

  /**
   * Baixa as informações de domain
   */
  protected verificarDados(): void {
    this.typeEntity = this.isProfessional ? "profissional" : "empresa";
    this.userService
      .getEntity(this.tokenService.getUser().id)
      .pipe(
        mergeMap((dados) => {
          // caso tenha uma foto ela baixa
          if (dados.photo_id)
            return this.imagemService
              .donwloadImagem({
                imagemId: dados.photo_id,
                mapToBase64: true,
              })
              .pipe(
                map(
                  (logo): IUser => ({
                    ...dados,
                    photo: logo,
                  })
                )
              );
          return of(dados);
        })
      )
      .subscribe((dados) => {
        if (dados === undefined) this.dados = {} as IUser;
        else {
          this.dados = dados;
          if (this.dados.name !== "admin" && this.typeEntity !== "empresa") {
            this.profissionalService
              .getEntitiesByParams(`user_id=${this.dados.id}`)
              .subscribe((value) => {
                if (Object.keys(value[0]).length !== 0) {
                  this.typeEntity = "profissional";
                } else {
                  this.matSnackBar.open("Erro ao carregar os dados!",
                  "X",
                  {
                    duration: 2000,
                    horizontalPosition: "right",
                    verticalPosition: "top",
                    panelClass: ["msg-error"],
                  });
                }
              });
          }
          if (
            this.dados.name !== "admin" &&
            this.typeEntity !== "profissional"
          ) {
            this.empresaService
              .getEntitiesByParams(`user_id=${this.dados.id}`)
              .subscribe(() => {
                this.typeEntity = "empresa";
              });
          }
        }
      });
  }

  ngOnInit(): void {
    this.verificarDados();
  }

  get isProfessional() {
    return (
      localStorage.getItem("profissional_id") !== undefined &&
      localStorage.getItem("profissional_id") !== null
    );
  }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import StringMask from 'string-mask';
import { IProfissional } from '../../../modules/landing-page/model/register.model';
import { CepPipe } from '../../pipes/cep/cep.pipe';
import { CpfCnpjPipe } from '../../pipes/cpfCnpj-pipe/cpfCnpj.pipe';

@Component({
    selector: 'app-form-perfil',
    templateUrl: './form-perfil.component.html',
    styleUrls: ['./form-perfil.component.scss'],
    providers: [CpfCnpjPipe, CepPipe, DatePipe]
})
export class FormPerfilComponent implements OnInit{
    @Input() entity : IProfissional
    @Input() empresa: boolean;
    contato_tel = '';
    cep = '';
    cidade = '';
    logradouro = '';
    cro = ''


    constructor( public cpfCnpjPipe:CpfCnpjPipe, public cepPipe:CepPipe, public datePipe: DatePipe){}

    ngOnInit(): void {
        if(this.entity.parceiro.contatos.length > 0 && this.entity.parceiro.contatos[0].contato !== ''){
            const tel = this.entity.parceiro.contatos.find(value => value.tag === 'telefone' || value.tag === 'whatsapp').contato
            if(tel.length === 8)
                var formatter = new StringMask("0000-0000", { reverse: true })
            else if(tel.length === 9)
                var formatter = new StringMask("00000-0000", { reverse: true })
            else if(tel.length === 10)
                var formatter = new StringMask("(00) 0000-0000", { reverse: true })
            else if(tel.length === 11)
                var formatter = new StringMask("(00) 00000-0000", { reverse: true })
            else if(tel.length === 13)
                var formatter = new StringMask("+00 (00) 0000-0000", { reverse: true })
            else
                var formatter = new StringMask("+00 (00) 00000-0000", { reverse: true })
            this.contato_tel = formatter.apply(tel)
        }if(this.entity.parceiro.enderecos.length > 0){
            this.cep = this.cepPipe.transform(this.entity.parceiro.enderecos[0].cep)
            this.cidade = this.entity.parceiro.enderecos[0].municipio.nome + '-' + this.entity.parceiro.enderecos[0].municipio.sigla_uf
            this.logradouro = this.entity.parceiro.enderecos[0].logradouro
        }
        if(!this.empresa){
            if(this.entity.formacoes.length > 0 ){
                var graduado = this.entity.formacoes.find(value => value.id === this.entity.formacao_favorita_id).data_termino 
                this.entity.graduado = graduado !== undefined ? this.datePipe.transform(graduado, 'shortDate') : ''
            }else this.entity.graduado = ''
        } 
    }
} 
<div class="d-md-flex justify-content-md-between align-items-center">
  <div
    class="d-flex justify-content-between justify-content-md-start align-items-center width-100"
  >
    <button
      [matMenuTriggerFor]="menuFilter"
      pButton
      pRipple
      icon="pi pi-fw pi-angle-down"
      class="p-button-rounded p-button-primary p-button-outlined mb-0 mr-2"
    ></button>

    <mat-menu #menuFilter="matMenu" class="text-center menu-content">
      <div class="content-itens-filter" style="width: 200px;">
        <h6 class="my-2">Ações</h6>
        <mat-accordion (click)="$event.stopPropagation()">
          <mat-expansion-panel>
            <mat-expansion-panel-header>Status</mat-expansion-panel-header>
            <mat-list style="display: flex; justify-content: center;">
              <mat-selection-list style="padding: 10px;">
                <mat-list-item
                  color="primary"
                  *ngFor="let status of entityStatusList"
                >
                  <button (click)="changeStatus(status.value)" mat-menu-item>
                    <mat-icon>{{ status.icon }}</mat-icon>
                    <span>{{ status.label }}</span>
                  </button>
                </mat-list-item>
              </mat-selection-list>
            </mat-list>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="tipo_entity !== 'empresa'">
            <mat-expansion-panel-header
              >Perfil de usuário</mat-expansion-panel-header
            >
            <mat-list style="display: flex; justify-content: center;">
              <mat-selection-list>
                <mat-list-item
                  color="primary"
                  *ngFor="let status of entityProfileList"
                >
                  <button (click)="changeProfile(status.value)" mat-menu-item>
                    <mat-icon>{{ status.icon }}</mat-icon>
                    <span>{{ status.label }}</span>
                  </button>
                </mat-list-item>
              </mat-selection-list>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-menu>
  </div>
</div>

import { TokenService } from 'viggo-sistemas-core-web';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable()
export class RedirectGuard implements CanActivate{
public constructor(
    protected tokenService: TokenService,
    protected router: Router
) {}
    canActivate(): boolean {   
        if(this.router.getCurrentNavigation().finalUrl.toString().startsWith('/auth/login')){
            this.router.navigate(['login/profile'])
            return false;
        }
        return true;
    }
}
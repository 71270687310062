import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService, EntityService } from 'viggo-sistemas-core-web';
import { IRole } from 'viggo-sistemas-core-web/modules/role/model/role.model';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RoleOverrideService extends EntityService<IRole> {
  constructor(
    protected http: HttpClient,
    protected configService: ConfigService
  ) {
    super({
      baseEntityEndPoint: 'role',
      urlBackend: configService.backendURL
    });
  }

  public roles$ = this.getEntities().pipe(
    map(domains => domains as IRole[]),
    catchError(err => {
      return throwError(err);
    })
  );
}

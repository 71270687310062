import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatSelectionList,
  MatSelectionListChange,
} from "@angular/material/list";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TokenService } from "viggo-sistemas-core-web";
import { CpfCnpjPipe } from "../../../../pipes/cpfCnpj-pipe/cpfCnpj.pipe";
import { ListItemOptions } from "../../models/entity-params";
import { LabelValueItem } from "../../models/utils.model";
import { ViggoCoreSearchHeaderViewMode } from "../controls-view-type/controls-view-type.component";
export interface SELECT {
  value: string;
  viewValue: string;
}

// interface StatusValue {
//   label: string;
//   value: string;
//   checked: boolean;
// }

// export interface STATUSLIST {
//   title: string;
//   value: Array<StatusValue>;
// }

@Component({
  selector: "app-search-header",
  templateUrl: "./search-header.component.html",
  styleUrls: ["./search-header.component.scss"],
  providers: [CpfCnpjPipe],
})
export class SearchHeaderComponent implements OnInit, OnChanges, AfterViewInit {
  selectedValue: string;
  selectedValue2: string;
  maxDate = new Date();

  @ViewChild(MatSelectionList) protected matSelectionList: MatSelectionList;

  public keywordFormInput1 = new FormControl();
  public keywordFormInput2 = new FormControl();
  public keywordFormInput3 = new FormControl();
  public keywordFormInput4 = new FormControl();
  public keywordFormInput5 = new FormControl();
  public keywordFormInput6 = new FormControl();
  public keywordFormDate1 = new FormControl();
  public keywordFormDate2 = new FormControl();
  public keywordCpf_Cnpj = new FormControl();
  public keywordSelect1 = new FormControl();
  public keywordSelect2 = new FormControl();
  public keywordSelect3 = new FormControl();

  cpfCnpjMask = "000.000.000-00*";

  public statusForm: FormControl;

  @Input() prefixTemplateViewMode: TemplateRef<Component>;

  @Input() suffixTemplateViewMode: TemplateRef<Component>;

  @Input() styleFields = "";

  @Input() showFirstCombo = true;

  @Input() showSecondCombo = true;

  @Input() hiddenFilter = true;

  @Input() hiddenInput = false;

  @Input() hiddenInput2 = false;

  @Input() hiddenInput3 = false;

  @Input() hiddenInput4 = false;

  @Input() hiddenInput5 = false;

  @Input() hiddenInput6 = false;

  @Input() hiddenInputDate1 = false;

  @Input() hiddenInputDate2 = false;

  @Input() hiddenInputCpfCnpj = false;

  @Input() hiddenInputSelect1 = false;

  @Input() hiddenInputSelect2 = false;

  @Input() hiddenInputSelect3 = false;

  @Input() placeHolder1: string;

  @Input() placeHolder2: string;

  @Input() placeHolder3: string;

  @Input() placeHolder4: string;

  @Input() placeHolder5: string;

  @Input() placeHolder6: string;

  @Input() labelInputDate1: string;

  @Input() labelInputDate2: string;

  @Input() placeHolderSelect1: string;

  @Input() placeHolderSelect2: string;

  @Input() placeHolderSelect3: string;

  @Input() stylesFieldFormInput1 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormInput2 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormInput3 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormInput4 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormInput5 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormInput6 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormInputCpfCnpj = "w-column col-12 md:col-12";

  @Input() stylesFieldFormSelect1 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormSelect2 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormSelect3 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormInputDate1 = "w-column col-12 md:col-12";

  @Input() stylesFieldFormInputDate2 = "w-column col-12 md:col-12";

  @Input() showDownloadButton = true;

  @Input() showReloadButton = true;

  @Input() showClearButton = true;

  @Input() showInfoButton = true;

  @Input() selectOptions1: SELECT[] = [];

  @Input() selectOptions2: SELECT[] = [];

  @Input() selectOptions3: SELECT[] = [];

  @Input() typeInput1: string = 'text';

  @Input() typeInput2: string = 'text';

  @Input() typeInput3: string = 'text';

  @Input() typeInput4: string = 'text';

  @Input() typeInput5: string = 'text';

  @Input() typeInput6: string = 'text';

  @Input() entityStatusList: Array<LabelValueItem<ListItemOptions>> = [
    { label: "Apenas ativos", value: "ACTIVE_ONLY" },
    { label: "Apenas inativos", value: "INACTIVE_ONLY" },
    { label: "Listar todos", value: "ACTIVE_AND_INACTIVE" },
  ];

  @Output() onChangeStatus: EventEmitter<ListItemOptions> = new EventEmitter<
    ListItemOptions
  >();

  @Output() public readonly onChangeSearchInput = new EventEmitter<string>();

  @Output() public onFilterStateInput1 = new EventEmitter<any>();

  @Output() public onFilterStateInput2 = new EventEmitter<any>();

  @Output() public onFilterStateInput3 = new EventEmitter<any>();

  @Output() public onFilterStateInput4 = new EventEmitter<any>();

  @Output() public onFilterStateInput5 = new EventEmitter<any>();

  @Output() public onFilterStateInput6 = new EventEmitter<any>();

  @Output() public onClearInput1 = new EventEmitter<any>();

  @Output() public onClearInput2 = new EventEmitter<any>();

  @Output() public onClearInput3 = new EventEmitter<any>();

  @Output() public onClearInput4 = new EventEmitter<any>();

  @Output() public onClearInput5 = new EventEmitter<any>();

  @Output() public onClearInput6 = new EventEmitter<any>();

  @Output() public onClearInputCpfCnpj = new EventEmitter<any>();

  @Output() public onClearSelect1 = new EventEmitter<any>();

  @Output() public onClearSelect2 = new EventEmitter<any>();

  @Output() public onClearSelect3 = new EventEmitter<any>();

  @Output() public onClearDate1 = new EventEmitter<any>();

  @Output() public onClearDate2 = new EventEmitter<any>();

  @Output() public onFilterStateDate1 = new EventEmitter<any>();

  @Output() public onFilterStateDate2 = new EventEmitter<any>();

  @Output() public onFilterStateCpfCnpj = new EventEmitter<any>();

  @Output() public onFilterStateSelect1 = new EventEmitter<any>();

  @Output() public onFilterStateSelect2 = new EventEmitter<any>();

  @Output() public onFilterStateSelect3 = new EventEmitter<any>();

  @Output() public readonly onReloadClick = new EventEmitter<void>();

  @Output() public readonly onSearchClick = new EventEmitter<boolean>();

  @Output() public readonly onClearKeyboard = new EventEmitter<void>();

  @Output() public readonly onChangePreviewType = new EventEmitter<string>();

  @Output() public readonly onChangeInfoClik = new EventEmitter<void>();

  @Output() public readonly onDownloadDataClik = new EventEmitter<void>();

  @Input() showLoadingBar = false;

  @Input() initialValueStatus: ListItemOptions;

  /**
   * Variáveis responsáveis por identificar qual o dispositivo
   */
  public mobileOnly = false;
  public desktopOnly = false;

  public ngOnInit() {
    var ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      this.mobileOnly = true;
      this.desktopOnly = false;
    } else {
      this.mobileOnly = false;
      this.desktopOnly = true;
    }
    this.statusForm = new FormControl();
    this.setMask();
    this.keywordCpf_Cnpj.valueChanges.subscribe(() => {
      this.setMask();
    });
    this.keywordSelect1.valueChanges.subscribe((value) => {
      if (value) this.onSearch();
    });
    this.keywordSelect2.valueChanges.subscribe((value) => {
      if (value) this.onSearch();
    });
    this.keywordSelect3.valueChanges.subscribe((value) => {
      if (value) this.onSearch();
    });
  }

  public disableFilter() {
    return (
      (this.keywordFormInput1.value === "" ||
        this.keywordFormInput1.value === null) &&
      (this.keywordFormInput2.value === "" ||
        this.keywordFormInput2.value === null) &&
      (this.keywordFormInput3.value === "" ||
        this.keywordFormInput3.value === null) &&
      (this.keywordFormInput4.value === "" ||
        this.keywordFormInput4.value === null) &&
      (this.keywordFormInput5.value === "" ||
        this.keywordFormInput5.value === null) &&
      (this.keywordFormInput6.value === "" ||
        this.keywordFormInput6.value === null) &&
      (this.keywordFormDate1.value === "" ||
        this.keywordFormDate1.value === null) &&
      (this.keywordFormDate2.value === "" ||
        this.keywordFormDate2.value === null) &&
      (this.keywordCpf_Cnpj.value === "" ||
        this.keywordCpf_Cnpj.value === null) &&
      !this.selectedValue &&
      !this.selectedValue2 &&
      (!this.statusForm.value || !this.statusForm.value[0])
    );
  }

  setMask() {
    if (this.keywordCpf_Cnpj.value) {
      if (this.keywordCpf_Cnpj.value.toString().length <= 11)
        this.cpfCnpjMask = "000.000.000-00*";
      else this.cpfCnpjMask = "00.000.000/0000-00";
    }
  }

  enterSearch(event) {
    if (event.key === "Enter") {
      this.onSearch();
    }
  }

  public ngOnChanges(): void {
    if (this.showLoadingBar)
      this.keywordFormInput1.disable({ emitEvent: false });
    else this.keywordFormInput1.enable({ emitEvent: false });

    if (this.showLoadingBar)
      this.keywordFormInput2.disable({ emitEvent: false });
    else this.keywordFormInput2.enable({ emitEvent: false });

    if (this.showLoadingBar)
      this.keywordFormInput3.disable({ emitEvent: false });
    else this.keywordFormInput3.enable({ emitEvent: false });

    if (this.showLoadingBar)
      this.keywordFormInput4.disable({ emitEvent: false });
    else this.keywordFormInput4.enable({ emitEvent: false });

    if (this.showLoadingBar)
      this.keywordFormInput5.disable({ emitEvent: false });
    else this.keywordFormInput5.enable({ emitEvent: false });

    if (this.showLoadingBar)
      this.keywordFormInput6.disable({ emitEvent: false });
    else this.keywordFormInput6.enable({ emitEvent: false });

    if (this.showLoadingBar)
      this.keywordFormDate1.disable({ emitEvent: false });
    else this.keywordFormDate1.enable({ emitEvent: false });

    if (this.showLoadingBar)
      this.keywordFormDate2.disable({ emitEvent: false });
    else this.keywordFormDate2.enable({ emitEvent: false });

    if (this.showLoadingBar) this.keywordCpf_Cnpj.disable({ emitEvent: false });
    else this.keywordCpf_Cnpj.enable({ emitEvent: false });

    if (this.showLoadingBar) this.keywordSelect1.disable({ emitEvent: false });
    else this.keywordSelect1.enable({ emitEvent: false });

    if (this.showLoadingBar) this.keywordSelect2.disable({ emitEvent: false });
    else this.keywordSelect2.enable({ emitEvent: false });

    if (this.showLoadingBar) this.keywordSelect3.disable({ emitEvent: false });
    else this.keywordSelect3.enable({ emitEvent: false });
  }

  constructor(
    protected cpfCnpjPipe: CpfCnpjPipe,
    protected matSnackBar: MatSnackBar,
    protected tokenService: TokenService
  ) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.statusForm.setValue([this.initialValueStatus]);

      this.matSelectionList.selectionChange.subscribe(
        (s: MatSelectionListChange) => {
          this.matSelectionList.deselectAll();
          s.options.forEach((o) => (o.selected = true));
          this.onChangeStatus.emit(this.statusForm.value);
        }
      );
    }, 1);
  }

  clearFilterInput1() {
    this.keywordFormInput1.setValue("");
    this.onClearInput1.emit(true);
  }

  clearFilterInput2() {
    this.keywordFormInput2.setValue("");
    this.onClearInput2.emit(true);
  }

  clearFilterInput3() {
    this.keywordFormInput3.setValue("");
    this.onClearInput3.emit(true);
  }

  clearFilterInput4() {
    this.keywordFormInput4.setValue("");
    this.onClearInput4.emit(true);
  }

  clearFilterInput5() {
    this.keywordFormInput5.setValue("");
    this.onClearInput5.emit(true);
  }

  clearFilterInput6() {
    this.keywordFormInput6.setValue("");
    this.onClearInput6.emit(true);
  }

  clearFilterSelect1() {
    this.keywordSelect1.setValue("");
    this.onClearSelect1.emit(true);
  }

  clearFilterSelect2() {
    this.keywordSelect2.setValue("");
    this.onClearSelect2.emit(true);
  }

  clearFilterSelect3() {
    this.keywordSelect3.setValue("");
    this.onClearSelect3.emit(true);
  }

  clearFilterCpfCnpj() {
    this.keywordCpf_Cnpj.setValue("");
    this.onClearInputCpfCnpj.emit(true);
  }

  clearFilterDate1() {
    this.keywordFormDate1.setValue("");
    this.onClearDate1.emit(true);
  }

  clearFilterDate2() {
    this.keywordFormDate2.setValue("");
    this.onClearDate2.emit(true);
  }

  public onClearKeyword(): void {
    this.keywordFormInput1.setValue("");
    this.keywordFormInput2.setValue("");
    this.keywordFormInput3.setValue("");
    this.keywordFormInput4.setValue("");
    this.keywordFormInput5.setValue("");
    this.keywordFormInput6.setValue("");
    this.keywordFormDate1.setValue("");
    this.keywordFormDate2.setValue("");
    this.keywordCpf_Cnpj.setValue("");
    this.keywordSelect1.setValue("");
    this.keywordSelect2.setValue("");
    this.keywordSelect3.setValue("");
    this.statusForm.reset();
    this.onClearKeyboard.emit();
    this.onReloadClick.emit();
  }

  public changePreviewType(type: ViggoCoreSearchHeaderViewMode): void {
    this.onChangePreviewType.emit(type);
  }

  public onSearch(): void {
    if (this.keywordCpf_Cnpj.value || this.keywordCpf_Cnpj.value === "")
      this.onFilterStateCpfCnpj.emit(this.keywordCpf_Cnpj.value);

    if (this.keywordFormInput1.value || this.keywordFormInput1.value === "")
      this.onFilterStateInput1.emit(this.keywordFormInput1.value);

    if (this.keywordFormInput2.value || this.keywordFormInput2.value === "")
      this.onFilterStateInput2.emit(this.keywordFormInput2.value);

    if (this.keywordFormInput3.value || this.keywordFormInput3.value === "")
      this.onFilterStateInput3.emit(this.keywordFormInput3.value);

    if (this.keywordFormInput4.value || this.keywordFormInput4.value === "")
      this.onFilterStateInput4.emit(this.keywordFormInput4.value);

    if (this.keywordFormInput5.value || this.keywordFormInput5.value === "")
      this.onFilterStateInput5.emit(this.keywordFormInput5.value);

    if (this.keywordFormInput6.value || this.keywordFormInput6.value === "")
      this.onFilterStateInput6.emit(this.keywordFormInput6.value);

    if (this.keywordFormDate1.value || this.keywordFormDate1.value === "")
      this.onFilterStateDate1.emit(this.keywordFormDate1.value);

    if (this.keywordFormDate2.value || this.keywordFormDate2.value === "")
      this.onFilterStateDate2.emit(this.keywordFormDate2.value);

    if (this.keywordSelect1.value)
      this.onFilterStateSelect1.emit(this.keywordSelect1.value);

    if (this.keywordSelect2.value)
      this.onFilterStateSelect2.emit(this.keywordSelect2.value);

    if (this.keywordSelect3.value)
      this.onFilterStateSelect3.emit(this.keywordSelect3.value);
    
    this.onSearchClick.emit(true);
  }
}

import { ConfigService, EntityService, TokenService } from 'viggo-sistemas-core-web';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEmpresa } from '../../../modules/landing-page/model/register.model';
import { IServicos } from '../../servicos/model/servicos.model';

@Injectable()
export class EmpresaService extends EntityService<IEmpresa> {
    constructor(
        protected http: HttpClient,
        protected configService: ConfigService,
        protected tokenService : TokenService
    ) {
        super({
            urlBackend: configService.backendURL,
            pluralBaseEntityEndPoint: 'empresas',
            baseEntityEndPoint: 'empresa'
        });
    }

    private getHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        if (this.tokenService.getToken())
            headers = headers.append('token', this.tokenService.getToken().id);
        return headers;
    }

    public adicionarServicos(servico: IServicos, id: string){
        const url = `${this.configService.backendURL}empresas/${id}/adicionar_servico`;

        const headers = this.getHeaders();

        return this.http.put<IServicos>(url, servico, {headers});
    }

    public removerServicos(servico_id: string, id: string){
        const url = `${this.configService.backendURL}empresas/${id}/remover_servico`;

        const headers = this.getHeaders();

        return this.http.put<IServicos>(url, {servico_id: servico_id}, {headers});
    }

    /**
     * Ativar empresa
     * @param entityId identificador do empresa
     */
    public ativarEmpresa(
        entityId: string,
    ){
        const url = `${this.baseURL}/${entityId}/ativar`;

        const headers = this.getHeaders();

        return this.http.put<{ empresa: IEmpresa }>(url,null, {headers});
    }

    /**
    * Inativar empresa
    * @param entityId identificador do empresa
    */
    public inativarEmpresa(
        entityId: string,
    ){
        const url = `${this.baseURL}/${entityId}/inativar`;

        const headers = this.getHeaders();

        return this.http.put<{ empresa: IEmpresa }>(url,null, {headers});
    }

    /**
    * Suspender empresa
    * @param entityId identificador do empresa
    */
    public suspenderEmpresa(
        entityId: string,
    ){
        const url = `${this.baseURL}/${entityId}/suspender`;

        const headers = this.getHeaders();

        return this.http.put<{ empresa: IEmpresa }>(url,null,{headers});
    }
}
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatSelectionList,
  MatSelectionListChange,
} from "@angular/material/list";
import { ListItemOptions } from "viggo-sistemas-core-web";
import { LabelValueItem } from "../../models/utils.model";

@Component({
  selector: "controls-action",
  templateUrl: "./controls-action.component.html",
})
export class ControlsActionComponent implements OnInit, AfterViewInit {
  /**
   * @description
   * Evento emitter para quando o botão de reload é clicado
   */
  @Output() public readonly onReloadClick = new EventEmitter<void>();

  /**
   * @description
   * Evento emitido quando é clicado no botão de informação
   */
  @Output() public readonly onChangeInfoClik = new EventEmitter<void>();
  /**
   * @description
   * Evento emitido quando é clicado no botão de dowload de informações
   */
  @Output() public readonly onDownloadDataClik = new EventEmitter<void>();

  @Output() public readonly onClearKeyword = new EventEmitter<void>();

  @Output() onChangeStatus: EventEmitter<ListItemOptions> = new EventEmitter<
    ListItemOptions
  >();

  @Input() disabledClearFilter = true;

  /**
   * Habilita o botao de donwload
   */
  @Input() showDownloadButton = true;
  /**
   * Habilita o botao de reload
   */
  @Input() showReloadButton = true;
  /**
   * Habilita o botao de informacao
   */
  @Input() showInfoButton = true;

  @Input() showClearButton = true;

  @Input() hiddenFilter = true;

  @Input() statusForm: FormControl;

  @Input() initialValueStatus: ListItemOptions;

  @Input() entityStatusList: Array<LabelValueItem<ListItemOptions>> = [
    { label: "Apenas ativos", value: "ACTIVE_ONLY" },
    { label: "Apenas inativos", value: "INACTIVE_ONLY" },
    { label: "Listar todos", value: "ACTIVE_AND_INACTIVE" },
  ];

  @ViewChild(MatSelectionList) protected matSelectionList: MatSelectionList;

  public ngOnInit() {
    this.statusForm = new FormControl();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.statusForm.setValue([this.initialValueStatus]);

      this.matSelectionList.selectionChange.subscribe(
        (s: MatSelectionListChange) => {
          this.matSelectionList.deselectAll();
          s.options.forEach((o) => (o.selected = true));
          this.onChangeStatus.emit(this.statusForm.value);
        }
      );
    }, 1);
  }

  /**
   * Emite o evento de download
   */
  public donwload() {
    this.onDownloadDataClik.emit();
  }

  /**
   * Emite o evento de download
   */
  public clearFilters() {
    this.onClearKeyword.emit();
  }

  /**
   * Emite o evento de reload
   */
  public reload() {
    this.onReloadClick.emit();
  }

  /**
   * Emite o evento de click nas informações
   */
  public info() {
    this.onChangeInfoClik.emit();
  }
}

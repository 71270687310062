import { Component, OnInit, Renderer2 } from "@angular/core";
import { CustomMenuService } from "@app/modules/global_services";

import { RouterItemPg, TokenService } from "viggo-sistemas-core-web";

import { MenuToolBarItem } from "viggo-sistemas-core-web/modules/toolbar/components/slide-menu-right/slide-menu-right.component";

interface buildMenu {
  label: string;
  items: RouterItemPg[];
  routerLink?: string[];
  icon?: string;
}
@Component({
  selector: "app-logged",
  templateUrl: "./logged.component.html",
})
export class LoggedComponent implements OnInit {
  public appName: string;
  public logoToolBar = "assets/images/logo.png";

  public menuItem = [] as buildMenu[];

  menuFast = [] as MenuToolBarItem[];

  menuProfile = [] as MenuToolBarItem[];

  // primeng

  // actions layout
  topbarMenuActive: boolean;
  menuActive: boolean;
  staticMenuDesktopInactive: boolean;
  mobileMenuActive: boolean;
  menuClick: boolean;
  mobileTopbarActive: boolean;
  topbarRightClick: boolean;
  topbarItemClick: boolean;
  activeTopbarItem: string;
  documentClickListener: () => void;
  configActive: boolean;
  configClick: boolean;
  rightMenuActive: boolean;
  menuHoverActive = false;
  searchClick = false;
  search = false;
  currentInlineMenuKey: string;
  inlineMenuActive: any[] = [];
  inlineMenuClick: boolean;

  //PrimeNG config
  topbarTheme = "indigo";
  menuTheme = "light";
  layoutMode = "light";
  menuMode = "slim";
  inlineMenuPosition = "bottom";
  inputStyle = "filled";
  ripple = true;
  isRTL = false;
  isActive = false;
  itemMenu = false;

  constructor(
    protected menu: CustomMenuService,
    protected tokenService: TokenService,
    private renderer: Renderer2
  ) {
    this.appName = "";
  }

  ngOnInit() {
    this.menuActive = !(this.isOverlay() && !this.isMobile());
    this.initMenuItem();
    this.initMenuToolBar();
  }

  protected initMenuToolBar() {
    this.menuProfile = [
      {
        title: "Informações de usuário",
        icon: "pi pi-user",
        action: true,
      },
      {
        title: "Alterar senha",
        icon: "pi pi-lock",
        action: true,
      },
      {
        title: "Sair",
        icon: "pi pi-power-off",
        action: true,
      },
    ] as MenuToolBarItem[];
  }

  protected initMenuItem() {
    // mount menu routes
    this.menu.onCreateMenuPrimeNg().subscribe((menuItem) => {
      if (!this.isMobile()) {
        const prevMenu = this.menuItem;
        const index = this.menuItem.findIndex((m) => m.label.includes("Menu"));
        if (index >= 0) {
          prevMenu.splice(index, 1);
          this.menuItem = prevMenu;
        }
        menuItem.map((item) => {
          this.menuItem.push({
            label: item.label,
            icon: item.icon,
            routerLink: item.routerLink,
            items: item.items,
          });
        });
      } else {
        this.menuMode = "overlay";
        this.menuItem.push({
          label: "Menu",
          icon: "pi pi-fw pi-play",
          items: [],
        });
        menuItem.map((item) => {
          this.menuItem[0].items.push({
            label: item.label,
            icon: item.icon,
            routerLink: item.routerLink,
            items: item.items,
            idElement: item.label,
          });
        });
      }
    });
  }

  ngAfterViewInit() {
    // hides the horizontal submenus or top menu if outside is clicked
    this.documentClickListener = this.renderer.listen("body", "click", () => {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
        this.menu.reset();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      if (!this.menuClick) {
        if (this.mobileMenuActive) {
          this.mobileMenuActive = false;
        }

        if (this.isOverlay()) {
          this.menuActive = false;
        }

        this.menuHoverActive = false;
        this.unblockBodyScroll();
      }

      if (!this.searchClick) {
        this.search = false;
      }

      if (
        this.inlineMenuActive[this.currentInlineMenuKey] &&
        !this.inlineMenuClick
      ) {
        this.inlineMenuActive[this.currentInlineMenuKey] = false;
      }

      this.inlineMenuClick = false;
      this.searchClick = false;
      this.configClick = false;
      this.topbarItemClick = false;
      this.topbarRightClick = false;
      this.menuClick = false;
    });
  }

  onMenuButtonClick(event) {
    this.menuActive = !this.menuActive;
    this.topbarMenuActive = false;
    this.topbarRightClick = true;
    this.menuClick = true;

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.mobileMenuActive = !this.mobileMenuActive;
      if (this.mobileMenuActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onTopbarMobileButtonClick(event) {
    this.mobileTopbarActive = !this.mobileTopbarActive;
    event.preventDefault();
  }

  onMenuClick(event) {
    this.menuClick = true;

    if (
      this.inlineMenuActive[this.currentInlineMenuKey] &&
      !this.inlineMenuClick
    ) {
      this.inlineMenuActive[this.currentInlineMenuKey] = false;
    }
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isStatic() {
    return this.menuMode === "static";
  }

  isHorizontal() {
    return this.menuMode === "horizontal";
  }

  isSlim() {
    return this.menuMode === "slim";
  }

  isOverlay() {
    return this.menuMode === "overlay";
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }
}

<!-- <div class="mb-2">
  <mat-card class="dashboard-card">
    <mat-card-header class="card-header default">
      <mat-card-title>
        <h1 class="title-text">
          {{ value }}
        </h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div mat-card-avatar class="default w-100">
        <span class="title-text" style="font-size: 20px;">{{ title }}</span>
        <mat-icon class="avatar-icon">{{ icon }}</mat-icon>
      </div>
    </mat-card-content>
  </mat-card>
</div> -->

<div class="card mb-0">
  <div class="flex justify-content-between">
    <span class="block text-500 font-medium">{{ title }}</span>
    <div
      [class]="
        'flex align-items-center justify-content-center border-round ' + color
      "
      [ngStyle]="{ width: '2.5rem', height: '2.5rem' }"
    >
      <i [class]="icon"></i>
    </div>
  </div>
  <div class="text-900 text-center font-medium text-xl mb-3">{{ value }}</div>
</div>

import { Injectable } from '@angular/core';
import { Route, PreloadingStrategy } from '@angular/router';

import { of as observableOf, Observable } from 'rxjs';

@Injectable()
export class SelectiveStrategyService implements PreloadingStrategy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload']) {
      return load();
    }
    return observableOf(null);
  }
}

<input
  #inputImage
  type="file"
  class="d-none"
  (click)="$event.stopPropagation()"
  accept="image/*"
  [formControl]="controlFormImageFile"
/>
<mat-card class="header">
  <h3 class="nome-user">Seja bem-vindo(a), {{ dados.name }}</h3>
</mat-card>
<mat-card class="content" *ngIf="typeProfile === 'profissional'">
  <div class="text-center mt-2 ml-2 mb-2">
    <span class="image-container">
      <img
        class="img-empresa"
        draggable="false"
        [src]="dados.photo || imagemDefault"
        alt="Foto perfil"
      />
      <div class="image-indicator">
        <button
          pButton
          pRipple
          (click)="inputImage.click()"
          id="btn-edit-user-image"
          icon="pi pi-camera"
          iconPos="right"
          label="Alterar foto"
          class="p-button-primary p-button-raised p-button-rounded"
        ></button>
      </div>
    </span>
  </div>
  <div class="informacoes-empresa">
    <div class="actions">
      <h3 class="display-name" color="primary">
        {{ entity.nome_razao_social }}
      </h3>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-fw pi-user"
        iconPos="right"
        label="Seu perfil"
        class="p-button-primary p-button-outlined btn-perfil"
        (click)="navigateToProfissionalProfile()"
      ></button>
    </div>
    <div class="descricao-empresa" *ngIf="!noFormations">
      <!-- <pre style="margin-left: 15px">Formado em:</pre> -->
      <div class="mt-2">
        <mat-card *ngIf="formacao" class="card-formation">
          {{ formacao }}
        </mat-card>
        <mat-card *ngIf="formacao === ''" class="card-formation">
          Sem formação
        </mat-card>
      </div>
    </div>
    <div class="row">
      <mat-card *ngIf="media_mentores !== 0" class="card-medias">
        <h3 class="nome-user">
          Média Mentores: {{ media_mentores.toFixed(2) }}
        </h3>
      </mat-card>
      <mat-card *ngIf="media_clinicas !== 0" class="card-medias">
        <h3 class="nome-user">
          Média Clínicas: {{ media_clinicas.toFixed(2) }}
        </h3>
      </mat-card>
    </div>
    <span class="descricao-empresa mb-3">{{ entity.observacao }}</span>
  </div>
</mat-card>
<mat-card class="content" *ngIf="typeProfile === 'empresa'">
  <div class="text-center mt-2 ml-2 mb-2">
    <span class="image-container">
      <img
        class="img-empresa"
        draggable="false"
        [src]="dados.photo || imagemDefault"
        alt="Foto perfil"
      />
      <div class="image-indicator">
        <button
          pButton
          pRipple
          (click)="inputImage.click()"
          id="btn-edit-user-image"
          icon="pi pi-camera"
          iconPos="right"
          label="Alterar foto"
          class="p-button-primary p-button-raised p-button-rounded"
        ></button>
      </div>
    </span>
  </div>
  <div class="informacoes-empresa">
    <div class="actions">
      <h3 class="display-name" color="primary">
        {{ entity.nome_razao_social }}
      </h3>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-fw pi-user"
        iconPos="right"
        label="Seu perfil"
        class="p-button-primary p-button-outlined btn-perfil"
        (click)="navigateToEmpresaProfile()"
      ></button>
    </div>
    <span class="descricao-empresa mb-3">{{ entity.observacao }}</span>
  </div>
</mat-card>

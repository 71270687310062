<div class="flex width-100 mt-3 align-items-center justify-content-between">
  <div class="flex align-items-center">
    <button
      *ngIf="hiddenFilter"
      [matMenuTriggerFor]="menuFilter"
      pButton
      pRipple
      icon="pi pi-fw pi-angle-down"
      class="p-button-rounded p-button-primary p-button-outlined mr-2"
    ></button>

    <mat-menu #menuFilter="matMenu" class="text-center menu-content">
      <div class="content-itens-filter">
        <h6 class="my-2">Filtros avançados</h6>
        <mat-accordion (click)="$event.stopPropagation()">
          <mat-expansion-panel>
            <mat-expansion-panel-header>Status</mat-expansion-panel-header>

            <mat-list>
              <mat-selection-list [formControl]="statusForm">
                <mat-list-option
                  checkboxPosition="before"
                  color="primary"
                  [value]="status.value"
                  *ngFor="let status of entityStatusList"
                >
                  {{ status.label }}
                </mat-list-option>
              </mat-selection-list>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-menu>
  </div>

  <div class="flex align-items-center">
    <button
      *ngIf="showDownloadButton"
      id="btn-download-data"
      mat-icon-button
      (click)="donwload()"
    >
      <mat-icon>get_app</mat-icon>
    </button>
    <button
      *ngIf="showClearButton"
      id="btn-clear-filter"
      pButton
      pRipple
      icon="pi pi-fw pi-filter-slash"
      class="p-button-danger p-button-outlined p-button-rounded p-button-icon mr-2"
      (click)="clearFilters()"
    ></button>
    <button
      *ngIf="showReloadButton"
      id="btn-reload-entities"
      pButton
      pRipple
      icon="pi pi-fw pi-refresh"
      class="p-button-primary p-button-outlined p-button-rounded p-button-icon"
      (click)="reload()"
    ></button>
    <button
      *ngIf="showInfoButton"
      id="btn-info"
      mat-icon-button
      (click)="info()"
    >
      <mat-icon>info</mat-icon>
    </button>
  </div>
</div>

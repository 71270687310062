import { Component, Input, OnInit } from "@angular/core";
import { IProfissional } from "../../../modules/landing-page/model/register.model";

export interface ValueItem {
  icon: string;
  label: string;
  value: string;
}

@Component({
  selector: "app-card-perfil-form",
  templateUrl: "./card-pefil-form.component.html",
  styleUrls: ["./card-perfil-form.component.scss"],
})
export class CardPefilFormComponent implements OnInit {
  @Input() entity: IProfissional;
  @Input() formacao = "";
  @Input() noFormations = false;
  @Input() tipo_entity = "";
  @Input() media_clinicas = 0;
  @Input() media_mentores = 0;

  imagemDefault = '../../../../assets/images/avatar.png';
  menu_expansion = [] as ValueItem[];

  grant_profile = [] as ValueItem[];

  constructor() {}

  ngOnInit(): void {
    if (this.tipo_entity === "empresa") {
      this.menu_expansion = [
        { icon: "person_add", label: "Ativar empresa", value: "ativar" },
        { icon: "person_remove", label: "Inativar empresa", value: "inativar" },
        { icon: "person_off", label: "Suspender empresa", value: "suspender" },
      ];
    } else {
      this.menu_expansion = [
        { icon: "person_add", label: "Ativar profissional", value: "ativar" },
        {
          icon: "person_remove",
          label: "Inativar profissional",
          value: "inativar",
        },
        {
          icon: "person_off",
          label: "Suspender profissional",
          value: "suspender",
        },
      ];
      this.tipo_entity === "mentor"
        ? (this.grant_profile = [
            {
              icon: "business_center",
              label: "Torne um profissional",
              value: "profissional",
            },
          ])
        : (this.grant_profile = [
            { icon: "badge", label: "Torne um mentor", value: "mentor" },
          ]);
    }
  }
}
